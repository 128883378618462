import { useEffect, useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import {
  ActiveCurrencyState,
  AssetAllDetailsState,
  isLockinState,
  maintenanceState,
  SocketTriggeredOnOrderState,
  userPersonalDetails,
  VisibleModifyState
} from "states";
import {
  Actions,
  Balance,
  Charts,
  Currency,
  InssuanceMsg,
  Main,
  Orders,
  OtherSongs,
  SbaDataRoom,
  TradingUnavailable,
} from "./components";
import { Image } from "@storybook";
import { AssetDetail } from "./components/AssetDetails";
import { assestType, LOAN_ASSETS, ORDERBOOK_SERIES } from "./constants";

import styles from "./Exchange.module.sass";
import AssetDetailNonPrivate from "./components/assets-details-non-private/AssestsInfoNonPrivate";
import { MusicCharts } from "components/Charts/music-charts";
import { SbaLoan } from "views/auction/adminAuctionDetails/sbaLoan";
import { Noasset } from "./components/Noasset";
import { Skeleton } from "@components/skeleton-loader";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { Chat } from "views/Chat/ChatModal";
import { Loader } from "components";
import { useWebSocket } from "@hooks/web-socket";

export const Exchange = () => {
  // globle state
  const maintenance = useRecoilValue(maintenanceState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const AssestsDetails = useRecoilValue(AssetAllDetailsState);
  const personalData = useRecoilValue(userPersonalDetails);
  const isLockin = useRecoilValue(isLockinState);
  const visibleModify =
    useRecoilValue(VisibleModifyState);

  const setSocketTriggeredOnOrderState = useSetRecoilState(SocketTriggeredOnOrderState);
  const { socket } = useWebSocket()
  
  const handleScroll = useCallback(() => {
    const element = document.getElementById("charts-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const { trackPageEvent } = useFullStoryTrackEvent();

  useEffect(() => {
    socket?.on("order", (data) => {
        setSocketTriggeredOnOrderState({ ...data, timeStamp: new Date().getTime() })
      }
    );
    return () => {
      socket?.off("order");
    };
  }, [socket]);

  useEffect(() => {
    trackPageEvent({
      pageName: "Exchange",
    });
  }, []);

  useEffect(() => {
    handleScroll();
  }, [AssestsDetails, handleScroll, activeCurrency?.coverImageUrl]);

  const isAssetFound = useMemo(() => {
    return !("id" in activeCurrency);
  }, [activeCurrency]);

  const renderDayUnit = useMemo(() => {
    return `${activeCurrency?.lockupPeriod?.duration} ${activeCurrency?.lockupPeriod?.unit}`
  }, [activeCurrency])

  return (
    <div className={styles.exchange}>
      <div className={styles.col}>
        <Currency />
      </div>
      <div className={styles.col}>
        {isAssetFound ? (
          <Noasset />
        ) : (
          <>
            <div className={styles.col2_row1}>
              <Main />
            </div>
            <div
              id="exchange--wrapper"
              className={`${styles.wrapper} ${styles.col2_row2}`}
            >
              <div id="charts-section" className={styles.row2_row1}>
                {LOAN_ASSETS[activeCurrency?.type] ? (
                  <div className={styles.prodImg}>
                    {activeCurrency?.coverImageUrl ? (
                      <Image url={activeCurrency?.coverImageUrl} />
                    ) : (
                      <div className={styles.prodLoader}>
                        <Skeleton width={1050} height={293} />
                      </div>
                    )}
                  </div>
                ) : (
                  <Charts />
                )}
              </div>
              {activeCurrency?.isIssuanceEnabled && !isLockin && (
                <InssuanceMsg
                  title="Minimum Order Requirement"
                  message="A minimum order of $25,000 is required for buy or sell transactions. Orders below this amount cannot be placed."
                />
              )}
              {
                activeCurrency?.isIssuanceEnabled &&
                isLockin &&
                !!Number(activeCurrency?.lockupPeriod?.duration) &&
              (
                <InssuanceMsg
                  title="Lockup Period Notice"
                  message={`${renderDayUnit} lockup period applies to this asset. Once purchased, the asset cannot be sold or involved in any secondary transactions for the duration of ${renderDayUnit}.`}
                />
              )}

              {activeCurrency?.id ? (
                <div
                  className={cn(styles.row2_row2, {
                    [styles.row2_row2_grid]:
                      activeCurrency?.sbaType !== ORDERBOOK_SERIES,
                  })}
                >
                  <Balance />
                  {activeCurrency?.sbaType !== ORDERBOOK_SERIES && (
                    <>
                      {maintenance?.isTreadingMaintenance ? (
                        <TradingUnavailable />
                      ) : (
                        <Actions isOrderModifying={visibleModify}/>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className={styles.loaderContainer}>
                  <Loader className="loader-grey" dimension={60} />
                </div>
              )}
              <div className={styles.row2_row3}>
                <Orders />
              </div>
              {LOAN_ASSETS[activeCurrency?.type] ? (
                <SbaDataRoom />
              ) : (
                <>
                  {activeCurrency?.lender_loan_number && (
                    <div className={styles.row2_row3}>
                      <div className="sbaLoanDetail">
                        <SbaLoan />
                      </div>
                    </div>
                  )}

                      <div className={styles.overview} id="assetDetails">
                    {activeCurrency?.id && (
                      <AssetDetail
                        isPrivate={activeCurrency?.type === assestType.privates}
                      />
                    )}
                        {activeCurrency?.type !== assestType.privates && activeCurrency?.type !== assestType.realEstate && activeCurrency?.type !== assestType.Horses && activeCurrency?.type !== assestType.crypto && (
                      <AssetDetailNonPrivate />
                    )}
                  </div>
                  {activeCurrency?.type === "music" && (
                    <>
                      <div className={styles.row2_row6}>
                        <OtherSongs />
                      </div>
                      <div className={styles.music_chart}>
                        <MusicCharts />
                      </div>
                    </>
                  )}
                </>
              )}

              <p className={styles.disclaimerFooter}>
                DISCLAIMER: Securities on this platform are offered by ARQ
                Securities (“ARQ”), a FINRA member broker-dealer and operator
                the ARQ Securities/ATS. ARQ is a wholly owned subsidiary of
                Satschel Inc. liquidity.io is a wholly owned subsidiary of
                Satschel Inc. and provides services to this Platform. By using
                the Site, you are subject to ARQ Securities Terms of Service and
                to our Privacy Policy.
                <br></br>
                <br></br>
                Please read these carefully before using the Platform. Although
                our Platform may describe opportunities to invest in various
                securities, we do not make recommendations regarding the
                appropriateness of any particular investment opportunity for any
                particular investor. We are not investment advisers and we do
                not provide investment advice or monitor, endorse, analyze, or
                recommend any securities. You should be aware that the value of
                investments in private securities may be impacted by limited
                disclosure of financial and other company information, and of
                transaction reporting; they may be less-liquid than comparable
                public-market securities and that private securities holdings
                are often subject to additional trading restrictions which may
                impact your ability to sell. You should consult your business
                adviser, accounting adviser, and/or attorney with respect to
                price, value, risk or other aspects of your security or
                investment, prior to entering into a transaction. Digital assets
                using blockchain, are speculative, involve a high degree of
                risk, are generally illiquid, may have no value, have limited
                regulatory certainty, are subject to potential market
                manipulation risks and may expose investors to loss of
                principal. Investments in private placements, start-up
                investments in particular, are also speculative and involve a
                high degree of risk. Investors must be able to afford the loss
                of their entire investment. Blockchain is a new technology and
                unproven in financial markets. Securities ownership is
                officially maintained at a securities custodian and the
                transaction agent's records supersede the blockchain. There is
                no guarantee that tokenization will enable any secondary market
                liquidity in the future and your investment may remain illiquid.
                <br></br>
                <br></br>
                <span>
                  Arq Securities is a member of{" "}
                  <a
                    href="https://brokercheck.finra.org/firm/summary/289499"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FINRA
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.sipc.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SIPC
                  </a>
                  .
                </span>
                <br></br>
                ARQ Securities Contact:
                <br></br>
                Eric Choi
                <br></br>
                Tel: +1 (406) 405-0873
                <br></br>
                Address: 350 Sugarbowl Cir., Whitefish, MT
              </p>
            </div>
          </>
        )}
        {!personalData?.isBlockChat && (
          <div className={styles.chat_dropdown}>
            {!!personalData.customerId && <Chat />}
          </div>
        )}
      </div>
    </div>
  );
};
export default Exchange;
