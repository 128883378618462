import { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { APIS } from "constant";
import { ActiveAssetTab, initialQuerySbaState, initialQueryMHLoanState, SbaState, ManuHomeLoanState, initialQueryEFLoanState, EquipmentFinanceLoanState } from "states";
import { useNetwork } from "hooks";
import { SkeletonWatchList } from "components";
import { SbaItem } from "./sba-item";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";

import styles from "../Currency/Currency.module.sass";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJsonToParams, getKeyValueObjectFromArray } from "@utils/common";
import { assetTabs } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";

export const Sba = () => {
  const activeTab = useRecoilValue(ActiveAssetTab);
  const isManuHomeLoan = activeTab.key === assetTabs.MHL
  const isEquipmentFinanceLoan = activeTab.key === assetTabs.EF
  const [filters, setFilters] = useRecoilState(isManuHomeLoan ? initialQueryMHLoanState : isEquipmentFinanceLoan ? initialQueryEFLoanState : initialQuerySbaState);

  //SBA7
  const { get: getSba7Data, loading: loadingISSBA } = useNetwork();
  const [sba, setSba] = useRecoilState(SbaState);
  const { get: getSba7 } = useNetwork();

  //Manu. home loan
  const { get: getManuHomeLoanData, loading: loadingISMHL } = useNetwork();
  const { get: getEquipmentFinanceLoanData, loading: loadingISEF } = useNetwork();
  const [manuHomeLoan, setManuHomeLoan] = useRecoilState(ManuHomeLoanState);
  const [equipmentFinanceLoan, setEquipmentFinanceLoan] = useRecoilState(EquipmentFinanceLoanState);

  const { get: getManuHomeLoan } = useNetwork();
  const { get: getEquipmentFinance } = useNetwork();

  const darkMode = useDarkMode(false);
  const refSBAInterval = useRef<any>(null);

  const mappedData = useMemo(() => {
    return {
      fetchMoreData: isManuHomeLoan ? getManuHomeLoanData : isEquipmentFinanceLoan ? getEquipmentFinanceLoanData : getSba7Data,
      loading: isManuHomeLoan ? loadingISMHL : isEquipmentFinanceLoan ? loadingISEF : loadingISSBA,
      setAssetData: isManuHomeLoan ? setManuHomeLoan : isEquipmentFinanceLoan ? setEquipmentFinanceLoan : setSba,
      assets: isManuHomeLoan ? manuHomeLoan : isEquipmentFinanceLoan ? equipmentFinanceLoan : sba,
      intervalFetch: isManuHomeLoan ? getManuHomeLoan : isEquipmentFinanceLoan ? getEquipmentFinance : getSba7,
    }
  }, [equipmentFinanceLoan, getEquipmentFinance, getEquipmentFinanceLoanData, getManuHomeLoan, getManuHomeLoanData, getSba7, getSba7Data, isEquipmentFinanceLoan, isManuHomeLoan, loadingISEF, loadingISMHL, loadingISSBA, manuHomeLoan, sba, setEquipmentFinanceLoan, setManuHomeLoan, setSba])


  // This code is for exchange explorer api polling
  const NewObjectWithNewIdAdded = useCallback(
    (assets: any, currentArray: any) => {
      const newAssets = currentArray?.filter((obj: any) => !assets?.[obj?.id]);
      if (newAssets?.length) {
        return getKeyValueObjectFromArray("id", newAssets);
      } else {
        return "";
      }
    },
    []
  );

  useEffect(() => {
    refSBAInterval.current = setInterval(() => {
      mappedData?.intervalFetch(`${APIS.Explorers}?include=${isManuHomeLoan ? "manufactured_home_loans" : isEquipmentFinanceLoan ? "equipment_finance" : "sba7"}`).then((res: any) => {
        if (res?.message === "ok") {
          const newSBA7Asset = NewObjectWithNewIdAdded(mappedData.assets, isManuHomeLoan ? res?.data?.manufactured_home_loans || [] : isEquipmentFinanceLoan ? res?.data?.equipment_finance || [] : res?.data?.sba7);
          if (newSBA7Asset) {
            mappedData?.setAssetData((prev: any) => {
              return {...newSBA7Asset, ...prev};
            });
          }
        }
      });
    }, 5000);
    return () => clearInterval(refSBAInterval.current);
  }, [mappedData.assets, isManuHomeLoan, isEquipmentFinanceLoan]);

  const handleFetchMore = useCallback(() => {
    const queryParams = { ...filters, offset: filters.offset + 1 };
    mappedData.fetchMoreData(`${APIS.Explorers}` + getJsonToParams(queryParams)).then((res: any) => {
      if (res) {
        mappedData?.setAssetData((prev: any) => {
          const newAssets = getKeyValueObjectFromArray("id", (isManuHomeLoan ? res?.data?.manufactured_home_loans || [] : isEquipmentFinanceLoan ? res?.data?.equipment_finance || [] : res?.data?.sba7))
          return {...prev, ...newAssets};
        });
      }
    });
    setFilters((prev) => ({ ...queryParams }));
  }, [filters, isManuHomeLoan,isEquipmentFinanceLoan]);

  const {svg: {no_data_light, no_data_dark}} = imageConfig;

  const assets = useMemo(() => {
    return Object?.values?.(mappedData?.assets || {});
  }, [mappedData?.assets]);

  return (
    <>
      {assets?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image fileName={darkMode.value ? no_data_dark : no_data_light} />
            <div>No Data Found</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={assets?.length || 0}
          next={handleFetchMore}
          hasMore={
            assets?.length >=
            filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="assets-scrollableDiv"
        >
          {assets?.map((data, index) => {
            return <SbaItem key={data?.id} index={index} {...(data ?? {})} />;
          })}
        </InfiniteScroll>
      )}

      {mappedData?.loading && <SkeletonWatchList listsToRender={4} />}
    </>
  );
};
