import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Loader, TextInput } from "components";
import { Confirmation, Image } from "@storybook";
import { getLogos } from "../../../account/constants";
import { DepositSuccess } from "./depositSucces";
import { SelectAccount } from "views/wallet/component/selectAccount";
import { AddAccount } from "views/wallet/component/addAccount";
import {
  AccountState,
  BalanceAmountState,
  DepositAmountState,
  DepositNoteState,
  depositStepState,
  DisplayUserState,
  PlaidTokenState,
  SelectedFundRecipientBank,
  SelectedFundRecipientBankAccount,
  userPersonalDetails,
  WithdrawAmountState,
} from "states";
import { useCurrency, useNetwork, useNotification } from "hooks";
import { APIS } from "constant";

import styles from "./deposit.module.sass";
import "./deposit-funds.scss";
import TextArea from "components/TextArea";
import { checkAllValues } from "utils/objectOperations";
import { WithdrawSuccess } from "views/wallet/component/withdraw/withdrawSucces";
import { UseTransaction } from "views/MyWallet/store/hooks";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import InsufficientFunds from "../InsufficientFunds/insufficientFunds";
import { CommaSeparatedInput } from "@storybook/comma-separated-input/comma-seprated-input";
import { formatNumberWithCommas } from "@utils/format-number";
import { trackEventSG } from "libs";
import { imageConfig } from "@utils/imageConfig";
import { useAppPlaidLink } from "@views/account/components/AccountBalances/plaidLinkHook";

interface IDeposit {
  handleCloseDeposit: () => void;
  buttonText?: string;
  component?: any;
  header?: string;
  transactionType?: string;
  amountLabel?: string;
  bankLabel?: string;
  subHeader?: string;
  availableBalance?: any;
  fundNote?: any;
}

export const DepositFunds = ({
  handleCloseDeposit,
  buttonText,
  component,
  subHeader,
  header,
  transactionType = "deposit",
  amountLabel,
  availableBalance,
  bankLabel,
  fundNote,
}: IDeposit) => {
  const [depositAmount, setDepositAmount] = useRecoilState(DepositAmountState);
  const setWithdrawAmount = useSetRecoilState(WithdrawAmountState);
  const [depositNote, setDepositNote] = useRecoilState(DepositNoteState);
  const balanceAmount = useRecoilValue(BalanceAmountState);
  const [depositState, setDepositState] = useRecoilState(depositStepState);
  const { get: getAccounts, data: accountsList } = useNetwork();

  const setToken = useSetRecoilState(PlaidTokenState);

  const [loader, setLoader] = useState<boolean>(false);
  const displayUser = useRecoilValue<any>(DisplayUserState);
  const { post: submitExchangeToken } = useNetwork();
  const { open, acccountGetLoading: loadingAfterAccountAdd, bankProceedLoader } = useAppPlaidLink();


  const userDetails = useRecoilValue<any>(userPersonalDetails);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [selectedItem, setSelectedItem] = useRecoilState(
    SelectedFundRecipientBank
  );
  const fundRecipient = useRecoilValue(AccountState);
  const [subSelectedItem, setSubSelectedItem] = useRecoilState(
    SelectedFundRecipientBankAccount
  );

  const [selectedBankItem, setSelectedBankItem] = useState<any>({
    item: selectedItem,
    subitem: subSelectedItem,
  });

  const [accounts, setAccounts] = useRecoilState(AccountState);

  const { convertToUSD } = useCurrency();
  const { post: deposit, data: depositData, error, statusCode } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { getTransactions, getFortressAccountInfo } = UseTransaction();
  const { trackEvent } = useFullStoryTrackEvent();
  const {
    post: generateToken,
    data: tokenResponse,
    loading: tokenloading,
    statusCode: tokenStatusCode,
    setStatusCode: setApiStatusCode,
  } = useNetwork();

  useEffect(() => {
    setDepositAmount("");
    setDepositNote("");
    setDepositState("amount");
     setSelectedItem(fundRecipient[0])
  }, [fundRecipient, setDepositAmount, setDepositNote, setDepositState, setSelectedItem, setSubSelectedItem]);


  useEffect(() => {
    const localSelectedItem = localStorage.getItem("selectedBank");
    const localSelectedSubItem = localStorage.getItem("selectedBankSubItem");
    const parsedSelectedItem = localSelectedItem ? JSON.parse(localSelectedItem) : null;
    const parsedSubSelectedItem = localSelectedSubItem ? JSON.parse(localSelectedSubItem) : null;

    const isValidSelection = fundRecipient.some((bank: any) =>
      bank.accounts.some(
        (account: any) => account.accountId === parsedSubSelectedItem?.accountId
      )
    );

    if (isValidSelection) {
      if (!selectedItem || !subSelectedItem) {
        setSelectedItem(parsedSelectedItem);
        setSubSelectedItem(parsedSubSelectedItem);
      }
    } else if (fundRecipient.length > 0) {
      const firstBank = fundRecipient[0];
      const firstSubItem = firstBank.accounts[0];
      setSelectedItem(firstBank);
      setSubSelectedItem({ ...firstSubItem });

      localStorage.setItem("selectedBank", JSON.stringify(firstBank));
      localStorage.setItem("selectedBankSubItem", JSON.stringify(firstSubItem));
    } 
  }, [fundRecipient, selectedItem, subSelectedItem, setSelectedItem, setSubSelectedItem]);


  const handleOnChange = useCallback(
    (e: any, value: any) => {
      if (isLoading) return;
      
      const validValue = /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,2})?$/.test(value);
      if (!validValue) return;
      setDepositAmount(value);
    },
    [isLoading, setDepositAmount]
  );

  const handleOnNoteChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      if (value.length > 10) return;
      setDepositNote(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const handleWithdraw = useCallback(() => {
      setDepositState('withDrawConfirmation')
  }, [])

  const handleClickBtn = useCallback(() => {
    if (isLoading) return;

  const depositAmountNumber = Number(depositAmount);
  if (transactionType === "deposit" && depositAmountNumber > 100) {
    errorNotification("Transaction Failed: Insufficient Balance");
    return; 
  }
    const payload = {
      tokenId: subSelectedItem?._id,
      accountId: subSelectedItem?.accountId,
      accountType: subSelectedItem?.subtype?.toUpperCase(),
      amount: depositAmount,
      // note: depositNote,
      transactionType: transactionType,
      mode: "ach",
    };
    trackEvent("ENTER_AMOUNT", {
      ...payload,
    });
    const validatePayload = checkAllValues(payload);
    
    if (validatePayload?.status) {
      setIsLoading(true);
      deposit(APIS.PAYMENT_INITIATION, payload);
    } else {
      errorNotification(
        validatePayload?.key === "tokenId"
          ? "Please select a Bank"
          : `${validatePayload?.key} should not be empty`
      );
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    balanceAmount,
    convertToUSD,
    depositAmount,
    selectedItem?._id,
    subSelectedItem?.accountId,
    userDetails,
  ]);

  useEffect(() => {
    if (depositData) {
      if (statusCode === 200) {
        setWithdrawAmount(depositAmount);
        setDepositState("success");
      }
      setIsLoading(false);
    }
  }, [
    depositAmount,
    depositData,
    setDepositState,
    setWithdrawAmount,
    statusCode,
  ]);

  useEffect(() => {
    if (depositData?.message && statusCode !== 200) {
      if (depositData?.message === "Insufficient balance") {
        setDepositState("insufficientBalance");
      } else {
        errorNotification(depositData?.message || "Something went wrong");
      }
    } else if (error) {
      errorNotification("Something went wrong");
    }
  }, [depositData, error, errorNotification, setDepositState, statusCode]);

  useEffect(() => {
    if (depositState === "success") {
      getTransactions();
      getFortressAccountInfo();
    }
  }, [depositState, getFortressAccountInfo, getTransactions]);


  const maskDots = useCallback((listsToRender: number) => {
    return Array(listsToRender)
      .fill(1)
      .map((index) => <span className={styles.maskDots}></span>);
  }, []);

  const handleAdd = useCallback(() => { 
    const {id:userId} = userDetails;
    const payload = {
      language: "en",
      countryCodes: ["US"],
    };
    trackEvent("ADD_NEW_BANK", {
      ...payload,
    });

    const eventData = { 
      timestamp: new Date(), 
      user_id: userId
    };
    trackEventSG("bank_account_addition_initiated_web", eventData);

    generateToken(APIS.GenerateLinkToken, payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenStatusCode === 400 && displayUser?.type === "Authorised User") {
      errorNotification(
        "As an authorised user, you are not allowed to add new bank account."
      );
    }
    if (tokenStatusCode === 401) {
      errorNotification("Logged in required");
    }
    setLoader(tokenloading);
    setApiStatusCode(null);
  }, [
    tokenloading,
    errorNotification,
    displayUser?.type,
    setApiStatusCode,
    tokenStatusCode,
  ]);

  const { logo } = imageConfig;

  useEffect(() => {
    if (tokenResponse?.token) {
      setToken(tokenResponse?.token);
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tokenResponse?.token]);

  useEffect(() => {
    if (accountsList) {
      setAccounts(accountsList?.data);
    }
  }, [accountsList, setAccounts]);

  const handleSubBank = useCallback(
    (item: any, subitem: any) => {
      setSelectedItem(item);
      setSubSelectedItem({ ...subitem });
      setDepositState("amount");
      trackEvent("SELECT_BANK", { item, subitem });
      localStorage.setItem("selectedBank", JSON.stringify({ ...item }));
      localStorage.setItem(
        "selectedBankSubItem",
        JSON.stringify({ ...subitem })
      );
    },
    [setDepositState, setSelectedItem, setSubSelectedItem, trackEvent]
  );

  const handleSelectBank = useCallback(
    (item: any, subitem: any) => {
      setSelectedBankItem({ item, subitem });
      handleSubBank(item, subitem);
    },
    [handleSubBank]
  );
  
  const RenderConfirmationWithdrawlAmount = () => {
    return (
      <div className="withdraw-confirmation-panel">
        <div className="panel-details">
          <h4 className="details-heading">Withdraw To</h4>
          <p className="details-body">{`${selectedItem?.bankName}(${subSelectedItem?.mask})`}</p>
        </div>
        <div className="panel-details">
          <h4 className="details-heading">Withdrawal Amount</h4>
          <p className="details-body">${formatNumberWithCommas(Number(depositAmount).toFixed(2))}</p>
        </div>
      </div>
    );
  };

  const RenderConfirmationDepositlAmount = () => {
    return (
      <div className={`withdraw-confirmation-panel ${transactionType === "deposit" ? 'confirmation-deposit' : ''}`}>
        <div className="panel-details">
          <h4 className="details-heading">Deposit Amount</h4>
          <p className="details-body">${formatNumberWithCommas(Number(depositAmount).toFixed(2))}</p>
        </div>
      </div>
    );
  };

  const renderAccounts = useMemo(() => {
    return (
      <div className={styles.renderedBankAccounts}>
        {fundRecipient?.map((item: any, index: Number) => {
          return (
            <div className={styles.allbankAccounts}>
              <div className={styles.allbankAccounts__bankTitle}>
                <Image
                  className={styles.allbankAccounts__bankTitle__logo}
                  fileName={
                    logo[item?.bankName ?? "default"] ?? logo["default"]
                  }
                />
                <div className={styles.allbankAccounts__bankTitle__details}>
                  <div className={styles.allbankAccounts__bankTitle__title}>
                    {item?.bankName}
                  </div>
                  <div className={styles.allbankAccounts__subAccount}>
                    {item?.accounts.map((subItem: any, index: any) => {
                      return (
                        <div
                          className={
                            styles.allbankAccounts__subAccount__account
                          }
                        >
                          <input
                            id="primary"
                            type="radio"
                            checked={
                              subSelectedItem?.accountId === subItem?.accountId
                            }
                            onChange={() => handleSelectBank(item, subItem)}
                            className={
                              styles.allbankAccounts__subAccount__account__checkBox
                            }
                            style={{
                              transform: "scale(1.5)",
                              backgroundColor: "#36383D",
                            }}
                          />

                          <div
                            className={
                              styles.allbankAccounts__subAccount__account__name
                            }
                          >
                            {subItem?.subtype}
                            <span
                              className={
                                styles.allbankAccounts__subAccount__account__mask
                              }
                            >
                              ({maskDots(3)}
                              {subItem?.mask})
                            </span>
                            {/* {subSelectedItem?.accountId ===
                              subItem?.accountId && (
                              <span className={styles.primary}>Primary</span>
                            )} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [fundRecipient, handleSelectBank, logo, maskDots, subSelectedItem?.accountId]);

  const renderAmount = useMemo(() => {
    return (
      <>
        <div className={styles.withdrawHeader}>
          {header ? header : "Deposit funds"}
          <span className={styles.withdrawSubHeader}>
            {subHeader ? subHeader : "Enter amount you wish to deposit."}
          </span>
        </div>
        <div className={styles.withdrawBody}>
          {/* {component && component} */}
          {/* <TextInput
            label={amountLabel ? amountLabel : "Amount to Deposit"}
            name="cardNumber"
            placeholder="Amount"
            type="number"
            value={depositAmount}
            onChange={handleOnChange}
            required
            classLabel={styles.customLabel}
            title={depositAmount > "0" ? "" : "This field is required"}
          /> */}
          <div className={styles.withdrawAmount}>
            <div className={styles.withdrawAmount__amountContainer}>
              <span className={styles.withdrawAmount__dollarSign}>$</span>{" "}
              <CommaSeparatedInput
                type="text"
                onChange={handleOnChange}
                value={depositAmount}
                placeholder="0.00"
                autoComplete="off"
                className={styles.withdrawAmount__amountInput}
                required
                name="deposit"
                autoFocus={true}
                style={{
                  width: `${depositAmount?.toString()?.length + 1}ch`,
                  minWidth: "4ch",
                }}
              />
            </div>
            <div className={styles.withdrawAmount__amounttodeposit}>
              {component ? component : "Amount to deposit"}
            </div>
          </div>
          {fundNote ? (
            fundNote
          ) : (
            <div className={styles.fundNote}>
              Deposits made by 12:30 PM EST will be settled and available on the
              same day.
            </div>
          )}
          <div className={styles.AddbankAccountLabel}>
            <span className={styles.label}>
              {bankLabel ? bankLabel : "Depositing from"}
            </span>
            {/* <span className={styles.addNewbank} onClick={handleAdd} >
            </span> */}
            <button className={styles.addNewbank} onClick={handleAdd} disabled={bankProceedLoader || loadingAfterAccountAdd}>
              {tokenloading ? <Loader dimension={16} /> : "Add New Bank"}
            </button>
          </div>
          <div className={styles.selectbankAccounts}>
            <div className={styles.selectedAccount}>
              <div className={styles.accounDetail}>
                <Image
                  className="bank-logo"
                  fileName={
                    logo[selectedItem?.bankName ?? "default"] ?? logo["default"]
                  }
                />
                <div className={styles.accountData}>
                  <div className={styles.accountName}>
                    {selectedItem?.bankName}
                  </div>
                  <div className={styles.accountNumber}>
                    <span className={styles.accounType}>
                      {subSelectedItem?.subtype}
                    </span>
                    ({maskDots(3)}
                    {subSelectedItem?.mask})
                    {/* {selectedItem?.bankName && (
                      <span className={styles.primary}>Primary</span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            {renderAccounts}
          </div>
          {/* <div className={styles.label}>Note</div>
          <TextArea
            label=""
            name="cardNumber"
            style={{
              height: "84px",
              borderRadius: "4px",
            }}
            placeholder="Write a note here..."
            type="text"
            value={depositNote}
            onChange={handleOnNoteChange}
            required
            className={styles.customLabel}
          /> */}
        </div>
        <div className="wallet-modal-footer">
          <button
            className={`btn-secondary ${isLoading ? "disabledBtn" : ""}`}
            onClick={isLoading ? () => {} : handleCloseDeposit}
          >
            Cancel
          </button>

          <button
            className={`btn-primary ${isLoading ? "disabledBtn" : ""}`}
            onClick={handleWithdraw}
            disabled={!(Number(depositAmount) > 0)}
          >
            {isLoading ? (
              <Loader dimension={20} />
            ) : buttonText ? (
              "Withdraw"
            ) : (
              "Deposit"
            )}
          </button>
        </div>
      </>
    );
  }, [bankLabel, bankProceedLoader, buttonText, component, depositAmount, fundNote, handleAdd, handleCloseDeposit, handleOnChange, handleWithdraw, header, isLoading, loadingAfterAccountAdd, logo, maskDots, renderAccounts, selectedItem?.bankName, subHeader, subSelectedItem?.mask, subSelectedItem?.subtype]);

  const confirmationLabel = useMemo(
    () => (transactionType === "deposit" ? "Confirm Deposit" : "Confirm Withdrawal"),
    [transactionType]
  );

  const renderDeposit = useMemo(() => {
    return <>{fundRecipient.length > 0 ? renderAmount : <AddAccount />}</>;
  }, [fundRecipient, renderAmount]);

  const depositRender: any = {
    amount: renderDeposit,
    method: <SelectAccount />,
    withDrawConfirmation: (
      <Confirmation
        title={transactionType === "deposit" ? "Do you want to proceed with this Deposit?" : "Do you want to proceed with this withdrawal?"}
        visible={true}
        description={
          <>
            {transactionType === "deposit" 
              ? "Please review the details below to confirm the transaction. Once confirmed, the amount will be added to your wallet balance upon successful processing." 
              : "Please review the details to confirm the transaction. Once confirmed, the amount will be deducted from your available balance."
            }
          </>
        }
        
        handleModal={handleClickBtn}
        handleClose={isLoading ? () => {} : handleCloseDeposit}
        boldDescription=""
        isEnableJsxElement={true}
        confirmationJsxElement={transactionType === "deposit" ?<RenderConfirmationDepositlAmount /> : <RenderConfirmationWithdrawlAmount /> }
        label={confirmationLabel}
        cancelLabel="Close"
        type="buy"
        cancelLoading={isLoading}
       />
    ),
    success:
      buttonText === "Withdraw" ? (
        <WithdrawSuccess
          transactionID={depositData?.transactionId}
          id={depositData?.id}
          status={depositData?.status}
          handleCloseDeposit={handleCloseDeposit}
          transactionHash={depositData?.transactionHash}
        />
      ) : (
        <DepositSuccess
          transactionID={depositData?.transactionId}
          id={depositData?.id}
          status={depositData?.status}
          handleCloseDeposit={handleCloseDeposit}
          userDetails={userDetails}
          trackEventSG={trackEventSG}
        />
      ),
    insufficientBalance: (
      <InsufficientFunds
        subHeading={"Please deposit funds to place a transaction."}
        fromAction={false}
        setVisibleModify={() => {}}
        showButton={buttonText !== "Withdraw"}
      />
    ),
  };

  return <>{depositRender[depositState]}</>;
};
