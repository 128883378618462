import type { RangeKeyDict } from "react-date-range";
import { differenceInMonths, subDays, isSameDay } from "date-fns";

import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useRecoilState } from "recoil";
import { useNotification } from "hooks";

import { SelectedDateRangeState } from "states";
import { DateRangePicker, defaultStaticRanges } from "components";
import { getFormattedDate } from "utils";

import "./date-picker.scss";
import { useLocalStorage } from "@hooks/local-storage";

interface IDatePicker {
  onSubmit: Function;
  buttonDisabled?:boolean
  setEnableFilterButton?:any
}

interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const DatePicker: FC<IDatePicker> = ({ onSubmit, buttonDisabled, setEnableFilterButton }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [range, setRange] = useState<IDateRange[] | any>();

  const [selectedDateRange, setSelectedDateRange] = useRecoilState(SelectedDateRangeState);
  const notFinalSelectedDateRange = useRef<any>(selectedDateRange);

  const { set: setLocalStorage } = useLocalStorage();
  const { errorNotification } = useNotification();
  
  const updateDateRange = (selectedDateRange: any) => {
    if(selectedDateRange[0].selectionType === 'custom'){
      const today = new Date();
      const { startDate, endDate } = selectedDateRange[0];
  
      // Convert string dates to Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);
      
      // Check if today and endDate are not the same day
      if (!isSameDay(today, end)) {
        const monthsDifference = differenceInMonths(end, start);
        
        // Check if the difference between startDate and endDate is more than a month
          if (monthsDifference < 1) {
              const newEndDate = today;
              const newStartDate = subDays(newEndDate, differenceInMonths(end, start) * 30);
              
              // Return a new object with updated dates
              return [
                  {
                      ...selectedDateRange[0],
                      startDate: newStartDate,
                      endDate: newEndDate,
                  },
              ];
          }
      }
  
      // Return the original object if no changes are needed
      return selectedDateRange;
    }
    else{
      return selectedDateRange;
    }
  
};

  useLayoutEffect(() => {
    const updatedDateRange = updateDateRange(selectedDateRange);
    notFinalSelectedDateRange.current = updatedDateRange;
    setSelectedDateRange(updatedDateRange)
  }, [])

  useEffect(() => {
    setRange(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (range) {
      const isSame = isSameDay(range[0].startDate, range[0].endDate);
      if (!isSame || count > 1) {
        // TODO: remove in future if of no use
        setLocalStorage("date-range", range[0]);
        setCount(0);
      }
    }
  }, [count, range]);

  const handleOpenDatePicker = useCallback(() => {
    setIsVisible(true);
    // TODO: remove in future if of no use
    // const today = new Date();
    // const isTodayRange =
    //   range &&
    //   range.length === 1 &&
    //   isSameDay(range[0].startDate, today) &&
    //   isSameDay(range[0].endDate, today);

    if(notFinalSelectedDateRange.current){
      setRange(notFinalSelectedDateRange.current)
    }
   
    // TODO: remove in future if of no use
   // If range matches today's date, reset the range to initial value
    // if (isTodayRange) {
    //   setRange(notFinalSelectedDateRange.current)
    //  // setRange(selectedDateRange);
    // } else if (range && count === 0) {
    //   setRange(notFinalSelectedDateRange.current)
    //   //setRange(selectedDateRange);
    // }
  }, []);

  function countDaysBetweenDates(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        throw new Error("Invalid date format. Please use 'YYYY-MM-DD'.");
    }
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    return differenceInDays;
}

  const handleChangeDateRange = useCallback(({ selection }: RangeKeyDict, selectionType: string) => {
   if (selection) {

    if(selection?.startDate && selection.endDate ){
      const {startDate , endDate} = selection
    const totaldays = countDaysBetweenDates(startDate.toDateString(), endDate.toDateString() )
    if(totaldays > 365){
      setEnableFilterButton(true)
      errorNotification("The date range must not exceed 365 days ");
    }else{
      setEnableFilterButton(false)
    }
    }
      setRange([{...selection as any, selectionType: selectionType}]);
      setCount((prev) => prev + 1);
    }
  }, []);

  const handleApplyDateRange = useCallback(() => {
    onSubmit();
    setIsVisible(false);
    notFinalSelectedDateRange.current = range
    setSelectedDateRange(range);
    
    // TODO: remove in future if of no use
    setLocalStorage("date-range", range[0]);
  }, [setSelectedDateRange, range, setLocalStorage, onSubmit]);

  const renderedDate = useMemo(() => {
    const { endDate, startDate }: any = selectedDateRange[0];

    const selectedRange = defaultStaticRanges.find(
      (date) => date.range().startDate === startDate && date.range().endDate === endDate,
    );

    if (selectedRange?.label) {
      return selectedRange.label;
    }
    const isSame = isSameDay(startDate, endDate);
    const fromDate = getFormattedDate(startDate, "MMM dd");
    const toDate = getFormattedDate(endDate, "MMM dd");
    if (isSame) {
      return fromDate;
    }

    return (
      <>
        <div className="selected-range--date">{fromDate}</div>
        <div className="selected-range--to">to</div>
        <div className="selected-range--date">{toDate}</div>
      </>
    );
  }, [selectedDateRange]);

  const selectedClass = classNames("calender-btn", {
    "calender-btn--active": isVisible,
  });

  const arrowIconClass = classNames("ri-arrow-down-s-line", {
    "arrow-icon--active": isVisible,
  });

  return (
    <div>
      <div onClick={handleOpenDatePicker} className={selectedClass}>
        <i className="ri-calendar-event-fill Date-picker--calendar-icon" />
        <span className="selected-range">{renderedDate}</span>
        <i className={arrowIconClass} />
      </div>
      <div className="picker-container">
        {isVisible && (
          <DateRangePicker
            range={range}
            rangeColor={["#4574f5"]}
            handleChangeRange={handleChangeDateRange}
            handleSubmit={handleApplyDateRange}
            setIsVisible={setIsVisible}
            buttonDisabled={buttonDisabled}
          />
        )}
      </div>
    </div>
  );
};
