import React, { useEffect, useMemo } from "react";
import { Container, PaymentUnavailable, WalletBalance } from "./conponent";
import { BankDetailsCard } from "./conponent/BankDetailsCard";
import { AllTransactionsData } from "./store/state";
import {  useRecoilValue } from "recoil";
import { UseTransaction } from "./store/hooks";
import { Loader, NoData } from "components";
import { TransactionCard } from "./conponent/TransactionCard";
import { useNavigate } from "react-router-dom";
import { AccountState, maintenanceState } from "states";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

import "./my-wallet.scss";
import "./my-wallet.scss";

export const MyWallet = () => {
  const navigate = useNavigate();
  const accounts = useRecoilValue(AccountState);
  const maintenance = useRecoilValue(maintenanceState);
  const darkMode = useDarkMode(false);

  const {svg: {bank}} = imageConfig;

  const totalBankAccounts = useMemo(() => {
    let totalCount = 0;

    accounts.forEach((bank) => {
      totalCount += bank.accounts?.length || 0;
    });

    return totalCount;


  }, [accounts]);


  const navigateToTransactoons = () => {
    navigate("/wallet/transactions");
  };

  const allTranstions = useRecoilValue(AllTransactionsData);
  const { getTransactions, transactionLoader } = UseTransaction();

  useEffect(() => {
    const options = { updateNewData: false };
    const query = {
      limit: 20,
      offset: 0,
    };
    getTransactions(query, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {svg: {no_data_light, no_data_dark}} = imageConfig;
 
  return (
    <Container>
      <div className="wallet">
        {maintenance?.isPaymentMaintenance ? (
          <PaymentUnavailable />
        ) : (
          <>
            <WalletBalance />
            <div className="wallet-account-details-container">
              <BankDetailsCard
                icon={bank}
                  header={"Linked Bank Accounts"}
                details={
                  `${totalBankAccounts} account${totalBankAccounts <= 1 ? "" : "s"}`
                }
                url={"/wallet/accounts"}
              />
              {/* <BankDetailsCard
            icon={card}
            header={"Cards"}
            details={"3 cards"}
            url={"/profile/cards"}
          /> */}
            </div>
          </>
        )}

        <div className="wallet-transaction-details-container">
          <div className="transaction-details-header">
            <p>Recent Transactions</p>
            {allTranstions?.data?.length !== 0 && allTranstions && (
              <small onClick={navigateToTransactoons}>See all</small>
            )}
          </div>
          <div className="transaction-container">
            {transactionLoader ? (
              <Loader />
            ) : allTranstions?.data?.length > 0 ? (
              allTranstions.data
                .filter((transaction: any, index: number) => index < 8)
                .map((transaction: any) => (
                  <TransactionCard
                    transactionType={transaction?.type}
                    transactiomnStatus={transaction?.status}
                    amount={transaction?.amount}
                    bankName={transaction?.bankName}
                    transactionDate={transaction?.createdAt}
                    flow={transaction?.flow}
                    transactionId={transaction?.transactionId}
                    id={transaction?.id}
                    accountNumber={transaction?.accountNumber}
                  />
                ))
            ) : (
              <NoData
                img={
                  darkMode.value
                    ? no_data_dark
                    : no_data_light
                }
                description="No transactions yet."
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default MyWallet
