import React, { ChangeEvent, useCallback, useState } from "react";
import styles from "./authorisedUser.module.sass";
import { CountryCode, ICountries } from "components";
import cn from "classnames";

import { TextInput } from "components";
import { ReactSwitch } from "@storybook";
import { useRecoilState } from "recoil";
import {
  InviteEmailInputState,
  InviteFirstNameInputState,
  InviteLastNameInputState,
  InvitePhoneInputState,
  LoginPhoneNumberMeta,
  tradingPermissionState,
  transactionPermissionState,
  userEmailState,
  userMobileNumberState,
  userNameState,
  userTypeState,
} from "states";

export const InviteUser = ({ inviteuserLoading }: any) => {
  const [userName, setUserName] = useRecoilState(userNameState);
  const [userEmail, setUserEmail] = useRecoilState(userEmailState);
  const [userType, setUserType] = useRecoilState(userTypeState);
  const [mobileNumber, setMobileNumber] = useRecoilState(userMobileNumberState);
    const [tradingPermission, setTradingPermission] = useRecoilState(tradingPermissionState);
  const [transactionPermission, setTransactionPermission] = useRecoilState(
    transactionPermissionState,
  );

  const [showFirstNameError, setShowFirstNameError] = useRecoilState(InviteFirstNameInputState);
  const [showLastNameError, setShowLastNameError] = useRecoilState(InviteLastNameInputState);
  const [showPhoneError, setShowPhoneError] = useRecoilState(InvitePhoneInputState);

  const [changeFirstNameError, setChangeFirstNameError] = useState(false);
  const [changeLastNameError, setChangeLastNameError] = useState(false);
  const [changeEmailError, setChangeEmailError] = useState(false);
  const [changePhoneError, setChangePhoneError] = useState(false);
    const [showInviteEmailError, setShowInviteEmailError] = useRecoilState(
    InviteEmailInputState
  );
  const [phoneNumberLengths, setPhoneNumberLengths] = useState({
    minLength: 9,
    maxLength: 13,
  });
  
  const changeNameHandle = useCallback(
    (name: string, e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const nameRegex =/^[a-zA-Z]*$/;
      if (name === "firstName") {
        if (!nameRegex.test(value)) {
          setChangeFirstNameError(true);
          setShowFirstNameError(true);
          return;
        }
        if (value.length < 3) {
          setChangeFirstNameError(false);
          setShowFirstNameError(true);
        }
        if (nameRegex.test(value) && value.length > 2) {
          setChangeFirstNameError(false);
          setShowFirstNameError(false);
        }
      }
      if (name === "lastName") {
        if (!nameRegex.test(value)) {
          setChangeLastNameError(true);
          setShowLastNameError(true);
          return;
        }
        if (value.length < 3) {
          setChangeLastNameError(false);
          setShowLastNameError(true);
        }
        if (nameRegex.test(value) && value.length > 2) {
          setChangeLastNameError(false);
          setShowLastNameError(false);
        }
      }
      if(nameRegex.test(value)){
        setUserName((prev: any) => {
          const prevState = JSON.parse(JSON.stringify(prev));
          prevState[name] = value;
          return { ...prevState };
        });
      }
    },
    [setShowFirstNameError, setShowLastNameError, setUserName],
  );

  const changeInputHandle = useCallback(
    (name: string, e: any) => {
      const { value } = e.target;
      const emailRegex = /^[\w+.-]+@\w+([.-]?\w+)*(\.\w{2,4})+$/;
      if (name === "email") {
        if (!emailRegex.test(value)) {
          setChangeEmailError(true);
          setShowInviteEmailError(true);
        }
        if (value.length < 1) {
          setChangeEmailError(false);
          setShowInviteEmailError(true);
        }
        if (emailRegex.test(value) && value.length > 0) {
          setChangeEmailError(false);
          setShowInviteEmailError(false);
        }
      }
      setUserEmail(e.target.value);
    },
    [setShowInviteEmailError, setUserEmail],
  );

  const handleToggle = (checked: any) => {
    setTradingPermission(checked);
  };
  const handleToggleTransaction = (checked: any) => {
    setTransactionPermission(checked);
  };

  const handleCountryCode = useCallback((e: ICountries) => {
    const countryCode = e.label;
    let minLength = 9;
    let maxLength = 13;
    
    setChangePhoneError(false);
    setShowPhoneError(false);

    switch (countryCode) {
      case "+91":
        minLength = 10;
        maxLength = 10;
        break;

      default:
        break;
    }

    setMobileNumber((prevState) => ({ ...prevState, countryCode}));
    setPhoneNumberLengths({ minLength, maxLength });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement> | any,
      selectedCountryCode: string
    ) => {
      const { value } = e.target;

      
      const re = /^[0-9\b]+$/;
      
      if (mobileNumber?.countryCode==="+91" && value.length > phoneNumberLengths.maxLength){
        setMobileNumber((prev)=>({...prev,phone:value.slice(0,10)}))
        return;
      }
      if (re.test(value) || value === "") {
        if (value.length < phoneNumberLengths.minLength && value.length > 0) {
          setChangePhoneError(true);
          setShowPhoneError(true);
        }
        if (value.length === 0) {
          setChangePhoneError(false);
          setShowPhoneError(true);
        }
        if (value.length >= phoneNumberLengths.minLength) {
        setChangePhoneError(false);
        setShowPhoneError(false);
        }
        setMobileNumber((prevState) => ({ ...prevState, phone: value }));
      }
      if (e.keyCode === 13) {
        // Handle Enter key press if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phoneNumberLengths.maxLength, phoneNumberLengths.minLength]
  );

  const handleUserTypeChange = useCallback(
    (selectedUserType: string) => {
      setUserType(selectedUserType);
    },
    [setUserType],
  );

  return (
    <div>
      <div className={styles.addInviteUser}>Invite User</div>
      <div className={styles.inviteUser}>
        <div className={cn(styles.addInput, styles.namefield)}>
          <TextInput
            label="First Name"
            name="firstName"
            classLabel={cn(styles.inputLabel, styles.firstName)}
            placeholder="John"
            data-testid="testFirstName"
            type="text"
            value={userName.firstName || ""}
            onChange={(e: any) => changeNameHandle("firstName", e)}
            isError={showFirstNameError}
            disabled={inviteuserLoading}
            errorMessage={
              changeFirstNameError
                ? "Only letters are allowed in the field."
                : "Name should be at least 3 character."
            }
            required
          />
          <TextInput
            label="Last Name"
            name="lastName"
            classLabel={cn(styles.inputLabel, styles.lastName)}
            placeholder="Smith"
            data-testid="testLastName"
            type="text"
            value={userName.lastName || ""}
            disabled={inviteuserLoading}
            onChange={(e: any) => changeNameHandle("lastName", e)}
            isError={showLastNameError}
            errorMessage={
              changeLastNameError
                ? "Only letters are allowed in the field."
                : "Name should be at least 3 character."
            }
            required
          />
        </div>

        <div className={cn(styles.addInput, styles.extraSpace)}>
          <TextInput
            label="Email Address"
            name="email"
            classLabel={styles.inputLabel}
            placeholder="johnsmith@mail.com"
            data-testid="testEmailId"
            type="email"
            value={userEmail}
            disabled={inviteuserLoading}
            onChange={(e: any) => changeInputHandle("email", e)}
            errorMessage={changeEmailError ? "Email is not valid" : "Email is required"}
            isError={showInviteEmailError}
            required
          />
        </div>
        <div className={styles.addInput}>
          <p className={styles.phoneLabel}>Phone Number</p>
          <div className={styles.input_wrapperr}>
            <CountryCode
              handleChangeCountry={handleCountryCode}
              countryCode={mobileNumber.countryCode as string}
            />
            <input
              type="tel"
              minLength={9}
              data-testid="testPhoneNumber"
              maxLength={13}
              className={styles.Invite_input_field}
              onChange={(e) =>
                onHandleChange(e, mobileNumber.countryCode as string)
              }
              onKeyDown={(e) =>
                onHandleChange(e, mobileNumber.countryCode as string)
              }
              value={mobileNumber.phone}
              disabled={inviteuserLoading}
              placeholder="XXXXXXXXXX"
              name="phone"
              autoComplete="tel"
            />
            {showPhoneError && (
              <span
                className={"input__error"}
                style={{
                  position: "absolute",
                  top: "57%",
                }}>
                {changePhoneError
                  ? `Number must be at least ${phoneNumberLengths.minLength} characters long`
                  : "Number is required"}
              </span>
            )}
          </div>
        </div>

        <div className={styles.addInput}>
          <div className={styles.userType}>Select User Type</div>
          <div className={styles.userDetailContainer}>
            <div className={styles.userTypeDetails}>
              <input
                // id="co-owner"
                id="primary"
                type="radio"
                value="co-owner"
                data-testid="testCoOwnerUserType"
                checked={userType === "co-owner"}
                onChange={() => handleUserTypeChange("co-owner")}
                disabled={userType !== "co-owner" && inviteuserLoading}
                style={{ transform: "scale(1.5)", marginTop: "5px", cursor: "pointer" }}
              />
              <p className={styles.tradingPermission} style={{ paddingTop: "2px" }}>
                Co-owner
              </p>
            </div>
            <div className={styles.userTypeDetails}>
              <input
                // id="authorised-user"
                id="primary"
                type="radio"
                value="Authorised User"
                checked={userType === "Authorised User"}
                data-testid="testAuthorisedUserType"
                onChange={() => handleUserTypeChange("Authorised User")}
                disabled={userType !== "Authorised User" && inviteuserLoading}
                style={{ transform: "scale(1.5)", marginTop: "5px", cursor: "pointer" }}
              />
              <p className={styles.tradingPermission} style={{ paddingTop: "2px" }}>
                Authorised User
              </p>
            </div>
          </div>
        </div>
        <div className={styles.permission}>
          <p className={styles.tradingPermission}>Trading Permission</p>{" "}
          <div>
            <ReactSwitch
              checked={tradingPermission}
              handleChange={handleToggle}
              isDisabled={inviteuserLoading}
              id={""}
            />
          </div>
        </div>
        <div className={styles.permission}>
          <p className={styles.tradingPermission}>Deposit and Withdrawal Permission</p>{" "}
          <div>
            <ReactSwitch
              checked={transactionPermission}
              handleChange={handleToggleTransaction}
              isDisabled={inviteuserLoading}
              id={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
