import { Button, Dropdown, Input, Loader } from "components";
import Checkbox from "components/Checkbox";
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { GetAuctionForm } from "../../constant";
import { convertFileToBase64 } from "utils";
import { APIS } from "constant";
import { useCurrency, useNetwork, useNotification } from "hooks";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { format, isValid } from "date-fns";
import { AuctionBasicForm } from "../auctionBasicForm";
import { AuctionDataRoom } from "../auctionDataRoom";
import { Json } from "types";
import { useRecoilValue } from "recoil";
import { userPersonalDetails } from "states";
import useDarkMode from "use-dark-mode";
import "./auction-main-form.scss";
import { AuctionLoanForm } from "../auctionLoanForm";
import {
  SBA_TYPE,
  UPLOAD_TYPE,
  LOAN_TYPE,
} from "@views/auction/create-auction/components/auctionLoanForm/constant";
import { AmountCommision } from "../amount-commision";
import { AuctionLoanType } from "../auction-loan-type";
import useAuctionHook from "@views/AuctionConsumer/hook";

const tradeType = [
  {
    label: "Classic",
    value: "classic",
  },
  {
    label: "Dutch",
    value: "dutch",
  },
];

export const AuctionMainForm = ({ editAuctionData }: any) => {
  const navigate = useNavigate();
  const [assetTradeType, setAssetTradeType] = useState<any>(tradeType[0].value);
  const { amountToBondPoints } = useCurrency();
  const { errorNotification, successNotification } = useNotification();
  const { patch: updateActionDetails } = useNetwork();
  const userData = useRecoilValue(userPersonalDetails);

  const { get: getAuctionDataById, loading } = useNetwork();
  const { get: getDataRopmFiles } = useNetwork();
  const { post: postAuctionForm } = useNetwork();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const [updatedDataRoomFileName, setUpdatedDataRoomFileName] = useState({});
  const { post: updateDataRoomFileNameData } = useNetwork();
  const [dataRoomFiles, setDataRoomFiles] = useState<null | any[]>(null);
  const [dataRoomDeleteFileID, setDataRoomDeleteFileID] = useState<any>([]);

  const [btnDisbaled, setBtnDisabled] = useState(false);
  const [gcpFileUploadingStatus, setGcpFileUploadingStatus] = useState<any>([]);
  const { put: updateDataRoomFileStatus } = useNetwork();
  const darkMode = useDarkMode(false);
  const [loanFormDataRoomFiles, setLoanFormDataRoomFiles] = useState<
    Json | any
  >([]);
  const [loanFormDeletedFiles, setLoanFormDeletedFiles] = useState<Json | any>(
    []
  );
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [dataRoomFilesRes, setDataRoomFilesRes] = useState<null | any[]>(null);

  const [auctionFormData, setAuctionFormData] = useState<any>({
    tradeType: "classic",
    startPrice: 1,
    stepPrice: 1,
    buynowPrice: 0,
    isBidAgentEnabled: false,
    startTime: new Date(),
    endTime: new Date(),
    autoRestart: false,
    name: "",
    category: "",
    product: "",
    description: "",
    assetImage: "",
    assetIcon: "",
    endPrice: 0,
    timeStepHours: 0,
    timeStepMinutes: 60,
    dutchStepQuantity: 0,
    dutchDuration: 0,
    auctionVisibility: false,
    antiSnipTimeStep: 0,
    restartSize: 0,
    folderId: "",
    antiSnipTimeToAdd: 0,
    symbol: "",
    symbolValue: "",
    reservePrice: 0,
    currentYield: 0,
    currentLoanBalance: 0,
    sbaType: SBA_TYPE.INDIVIDUAL,
    loanType: LOAN_TYPE.WHOLE_LOANS,
  });

  const { assetClassicForm, assetDutchForm } = GetAuctionForm(auctionFormData);
  const { isSbaLoan } = useAuctionHook(auctionFormData);

  const stepsQuantity: any = useMemo(() => {
    const {
      tradeType,
      startPrice,
      stepPrice,
      reservePrice,
      timeStepHours,
      timeStepMinutes,
    } = auctionFormData;
    try {
      if (
        startPrice > 0 &&
        stepPrice > 0 &&
        reservePrice > 0 &&
        tradeType !== "classic"
      ) {
        const netPrice = startPrice - reservePrice;
        const stepQuantityCalculated = Math.round(netPrice / stepPrice);
        const dutchDurationCalculated = Math.round(
          stepQuantityCalculated * (timeStepHours * 60 + timeStepMinutes)
        );
        return { stepQuantityCalculated, dutchDurationCalculated };
      } else {
        return { stepQuantityCalculated: 0, dutchDurationCalculated: 0 };
      }
    } catch (err: any) {
      errorNotification(err?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auctionFormData.startPrice,
    auctionFormData.stepPrice,
    auctionFormData.reservePrice,
    auctionFormData.timeStepHours,
    auctionFormData.timeStepMinutes,
  ]);

  useEffect(() => {
    setAuctionFormData((prev: any) => ({
      ...prev,
      dutchStepQuantity: stepsQuantity.stepQuantityCalculated,
      dutchDuration: stepsQuantity.dutchDurationCalculated,
    }));
  }, [stepsQuantity]);

  useEffect(() => {
    if (userData) {
      setAuctionFormData((prev: any) => ({
        ...prev,
        symbol: userData?.firstName + "." + userData?.lastName,
      }));
    }
  }, [userData]);

  const formatAuctionResponse = (data: any) => {
    const currentData = { ...data };
    const isSbaLoan =
      currentData.product === "SBA-7" && currentData.category === "Loans";
    setAuctionFormData({
      ...currentData,
      buynowPrice: isSbaLoan
        ? amountToBondPoints(
            currentData?.currentLoanBalance,
            currentData?.buynowPrice
          )
        : currentData?.buynowPrice,
      startPrice: isSbaLoan
        ? amountToBondPoints(
            currentData?.currentLoanBalance,
            currentData?.startPrice
          )
        : currentData?.startPrice,
      stepPrice: isSbaLoan
        ? amountToBondPoints(
            currentData?.currentLoanBalance,
            currentData?.stepPrice
          )
        : currentData?.stepPrice,
      reservePrice: isSbaLoan
        ? amountToBondPoints(
            currentData?.currentLoanBalance,
            currentData?.reservePrice
          )
        : currentData?.reservePrice,
      currentYield: currentData?.metadata?.currentYield || 0,
      totalWeightedLoanBalance:
        currentData?.metadata?.totalWeightedLoanBalance || 0,
      sbaType: !!currentData?.sbaType
        ? currentData.sbaType
        : SBA_TYPE.INDIVIDUAL,
      loanType: currentData?.metadata?.loanType || LOAN_TYPE.WHOLE_LOANS,
    });
    setAssetTradeType(currentData.tradeType);
  };

  useEffect(() => {
    if (editAuctionData.auctionId && editAuctionData.isEditable) {
      getAuctionDataById(`${APIS.Auction}/${editAuctionData.auctionId}`)
        .then((response) => {
          formatAuctionResponse(response.data);
        })
        .catch((err) => {
          errorNotification("We are not able to get data at this moment");
        });
    }
  }, [editAuctionData, getAuctionDataById]);

  useEffect(() => {
    if (auctionFormData?.id && auctionFormData?.folderId) {
      // this means we have a file
      getDataRopmFiles(`${APIS.AuctionDataRoom}/${auctionFormData?.folderId}`)
        .then((response: any) => {
          if (response.message === "ok") {
            const arr: any = [];
            const loanArr: any = [];
            response?.data?.forEach((items: any) => {
              let data = {
                ...items,
                fileName: items.docName,
                name: items.docName,
                size: items.fileSize,
                uploadType: items.uploadType,
                downLoadingStatus: 100,
                isCancelled: false,
                abortController: null,
                isfileuploading: false,
                isSuccessfullyUploaded: true,
                loadingStatus: "completed",
                status: "SUCCESS",
              };
              if (!!items.uploadType) {
                const index = items.uploadType === UPLOAD_TYPE.BIDSHEET ? 0 : 1;
                loanArr[index] = data;
              } else {
                arr.push(data);
              }
            });
            setDataRoomFilesRes((prev: any) => [...arr]);
            setLoanFormDataRoomFiles([...loanArr]);
          } else {
            errorNotification("failed to load data room files");
          }
        })
        .catch((err: any) => {
          errorNotification(err?.message);
        });
    }
  }, [auctionFormData?.id]);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let { name, value, pattern } = e.target;
      let reg = new RegExp(pattern);
      let result = reg.test(value);
      if (!result) return;
      if (name === "startPrice" && auctionFormData.stepPrice < 1) {
        let stepValue = Math.floor(parseInt(value) / 100);
        stepValue = stepValue > 1 ? stepValue : 1;
        setAuctionFormData((prev: any) => ({
          ...prev,
          [name]: value,
          stepPrice: stepValue.toString(),
        }));
      } else {
        setAuctionFormData((prev: any) => ({ ...prev, [name]: value }));
      }
    },
    [auctionFormData]
  );

  const onChangeTimeHandler = useCallback(
    (value: React.ChangeEvent<HTMLInputElement> | any, name: string) => {
      // const { name, value } = e.target;
      setAuctionFormData((prev: any) => ({
        ...prev,
        [name]: value.toDate(),
      }));
    },
    []
  );

  const handleCheckBox = useCallback(
    (e: any) => {
      const { name, checked } = e.target;
      setAuctionFormData((prev: any) => ({ ...prev, [name]: checked }));
    },
    [auctionFormData]
  );

  const handleImageInputChange = useCallback(
    async (file: any, imageType: string) => {
      const fileName: any = await convertFileToBase64(file);
      const type = file.type;
      setAuctionFormData((prev: any) => ({
        ...prev,
        [imageType]:
          imageType === "assetImage"
            ? [{ image: fileName, type }]
            : { image: fileName, type },
      }));
    },
    [auctionFormData]
  );
  
  const switchForm = useCallback(
    (formData: any) => {
      return formData?.map((items: any) => {
        if (items.type === "input") {
          return (
            <Input
              key={items.name}
              placeholder=""
              label={items.title}
              value={auctionFormData[items.name]}
              inputType={items.inputType}
              handleChange={onChangeHandler}
              inputName={items.name}
              required={items.required}
              suffixText={items.symbol}
              readonly={items.readonly}
              pattern={items.pattern}
              inputDesc={items?.description ?? ""}
            />
          );
        } else if (items.type === "date") {
          return (
            <>
              <div className="date-picker">
                <label>
                  {items.title}
                  {items.required ? "(Required)" : ""}
                </label>
                <DatePicker
                  value={auctionFormData[items.name]}
                  name={items.name}
                  onOpenPickNewDate={false}
                  onChange={(value) => onChangeTimeHandler(value, items.name)}
                  className={`${
                    darkMode.value ? "bg-dark" : ""
                  }  custom-calendar`}
                  placeholder="MM/DD/YYYY --:--"
                  format="MM/DD/YYYY hh:mm a"
                  plugins={[<TimePicker hideSeconds />]}
                  minDate={
                    items.name === "startTime"
                      ? new DateObject()
                      : new DateObject(
                          new Date(auctionFormData.startTime).getTime()
                        )
                  }
                />
              </div>
            </>
          );
        } else {
          return (
            <Checkbox
              key={items.name}
              note=""
              className={"checkbox"}
              value={auctionFormData[items.name]}
              onChange={handleCheckBox}
              content={items.title}
              name={items.name}
            />
          );
        }
      });
    },
    [
      auctionFormData,
      darkMode.value,
      handleCheckBox,
      onChangeHandler,
      onChangeTimeHandler,
    ]
  );

  const getForm = useMemo(() => {
    return assetTradeType === "classic" ? assetClassicForm : assetDutchForm;
  }, [assetClassicForm, assetDutchForm, assetTradeType]);
  
  const renderForm = useMemo(() => {
    if (getForm.length > 0) {
      return getForm?.map((items: any) => {
        return (
          <div key={items.header} className="auction-form-wrapper">
            <p className="auction-form-header">{items.header}</p>
            <div className="auction-form">{switchForm(items.form)}</div>
          </div>
        );
      });
    }
  }, [getForm, switchForm]);

  const updateAuctions = (payload: Json, isPublished: boolean = true) => {
    updateActionDetails(
      `${APIS.Auction}/${editAuctionData.auctionId}`,
      payload,
      {
        apiResponse: true,
      }
    )
      .then(async (res) => {
        if (res?.message === "ok" || res?.message === "Auction updated") {
          await updateDataRoomFilesAfterSuccess();
          successNotification(`Auction Updated successfully`);
          navigate(`/admin/${isPublished ? "auction" : "drafts"}`);
        } else {
          errorNotification(res?.message);
        }
      })
      .catch((error) => {
        errorNotification("Something went wrong");
      })
      .finally(() => {
        setIsFormLoading(false);
      });
  };

  const createAuctions = (payload: Json, isPublished: boolean) => {
    postAuctionForm(`${APIS.Auction}`, payload, { apiResponse: true })
      .then(async (res) => {
        if (res?.message === "ok") {
          await updateDataRoomFilesAfterSuccess();
          successNotification(
            `${isPublished ? "Auction" : "Draft"} created successfully`
          );
          navigate(`/admin/${isPublished ? "auction" : "drafts"}`);
        } else {
          errorNotification(res?.message);
        }
      })
      .catch((error) => {
        errorNotification("Something went wrong");
      })
      .finally(() => {
        setIsFormLoading(false);
      });
  };

  const ErrorMessage = (inputName: string, message: string) => {
    const input: any = document.getElementsByName(`${inputName}`);
    input[0]?.setCustomValidity(message);
    input[0]?.reportValidity();

    setTimeout(() => {
      input[0]?.setCustomValidity("");
    }, 3000);
    return false;
  };

  const valiDateAuctionname = (): any => {
    if (auctionFormData.name.trim() === "") {
      const input: any = document.getElementsByName(`name`);
      input[0]?.reportValidity();
      return false;
    } else if (auctionFormData.name.trim().length < 3) {
      const input: any = document.getElementsByName(`name`);
      input[0]?.setCustomValidity(`Minimum 3 characters required`);
      input[0]?.reportValidity();

      setTimeout(() => {
        input[0]?.setCustomValidity("");
      }, 1000);
      return false;
    } else if (!auctionFormData?.symbolValue?.trim()) {
      const input: any = document.getElementsByName(`symbolValue`);
      input[0]?.setCustomValidity(`Symbol can't be empty`);
      input[0]?.reportValidity();
      setTimeout(() => {
        input[0]?.setCustomValidity("");
      }, 1000);
      return false;
    }

    return true;
  };

  const valiDatePublishForm = () => {
    let validationSuccess: boolean = true;
    validationSuccess = valiDateAuctionname();
    if (validationSuccess) {
      for (let j = 0; j < getForm.length && validationSuccess; j++) {
        const item = getForm[j];
        for (let i = 0; i < item.form?.length; i++) {
          const formField: any = item.form[i];
          if (formField.required && formField.inputType === "tel") {
            if (
              auctionFormData.product === "SBA-7" &&
              auctionFormData.category === "Loans" &&
              parseFloat(auctionFormData.stepPrice) <= 0
            ) {
              return (validationSuccess = ErrorMessage(
                "stepPrice",
                `Price step cannot be 0`
              ));
            } else if (
              !(
                auctionFormData.product === "SBA-7" &&
                auctionFormData.category === "Loans"
              ) &&
              parseInt(auctionFormData.stepPrice) < 1
            ) {
              return (validationSuccess = ErrorMessage(
                "stepPrice",
                `Price step cannot be less than $1 or in decimal`
              ));
            }
          } else if (
            auctionFormData.tradeType === "classic" &&
            auctionFormData.buynowPrice !== "" &&
            parseFloat(auctionFormData.buynowPrice) <=
              parseFloat(auctionFormData.startPrice) &&
            !(
              auctionFormData.product === "SBA-7" &&
              auctionFormData.category === "Loans"
            )
          ) {
            return (validationSuccess = ErrorMessage(
              "buynowPrice",
              `Buy now price cannot be lesser or equal to the start price`
            ));
          } else if (
            auctionFormData.tradeType === "dutch" &&
            formField.required &&
            parseFloat(auctionFormData[formField.name]) === 0
          ) {
            return (validationSuccess = ErrorMessage(
              formField.name,
              `${formField.title} cannot be 0`
            ));
          } else if (
            auctionFormData.tradeType === "dutch" &&
            parseInt(auctionFormData.stepPrice) >=
              parseFloat(auctionFormData.startPrice)
          ) {
            return (validationSuccess = ErrorMessage(
              "stepPrice",
              `Step price cannot be more or equal to the Start price`
            ));
          } else if (
            auctionFormData.tradeType === "dutch" &&
            parseFloat(auctionFormData.reservePrice) >=
              parseFloat(auctionFormData.startPrice)
          ) {
            return (validationSuccess = ErrorMessage(
              "reservePrice",
              `Reserve price cannot be more or equal to the Start price`
            ));
          } else if (
            formField.required &&
            formField.inputType === "date" &&
            auctionFormData[formField.name] === null
          ) {
            return (validationSuccess = ErrorMessage(
              formField.name,
              `${formField.title} field is required`
            ));
          } else if (
            formField.required &&
            formField.inputType === "date" &&
            auctionFormData[formField.name] !== null
          ) {
            if (auctionFormData.endTime === null) {
              return (validationSuccess = ErrorMessage(
                "endTime",
                `End time field is required and greater than the Start time`
              ));
            }

            // check dates
            const today = new Date();
            const countStartTime = new Date(auctionFormData.startTime);
            const countEndTime = new Date(auctionFormData.endTime);

            if (countStartTime < today) {
              return (validationSuccess = ErrorMessage(
                "startTime",
                `Start time cannot be in past`
              ));
            } else if (countEndTime.getTime() - countStartTime.getTime() < 0) {
              return (validationSuccess = ErrorMessage(
                "endTime",
                `End time cannot be before Start time`
              ));
            }

            if (auctionFormData.tradeType === "dutch") {
              const auctionDuration =
                countEndTime.getTime() - countStartTime.getTime();
              const totalTimeStep =
                (auctionFormData.timeStepHours || 0) * 60 * 60 * 1000 +
                auctionFormData.timeStepMinutes * 60 * 1000;
              if (auctionDuration <= totalTimeStep) {
                return (validationSuccess = ErrorMessage(
                  "timeStepMinutes",
                  `Time step cannot be more than the auction duration`
                ));
              }
            }

            if (
              auctionFormData.product === "SBA-7" &&
              auctionFormData.category === "Loans"
            ) {
              const loanFileError = loanFormDataRoomFiles?.filter(
                ({ status }: Json) => status !== "SUCCESS"
              );
              if (loanFileError.length) {
                errorNotification("Please fix your loan files before proceed");
                return (validationSuccess = false);
              }
            }
          }
        }
      }
    }

    return validationSuccess;
  };

  const getPayloadToSubmitForm = (
    formData: any,
    isPublished: boolean = true
  ) => {
    let payload: Json = {};
    if (editAuctionData.auctionId && editAuctionData.isEditable) {
      const formattedStartTime = formData.startTime
        ? format(new Date(formData.startTime), "yyyy-MM-dd'T'HH:mm:00XXX")
        : null;

      const formattedEndTime = formData.endTime
        ? format(new Date(formData.endTime), "yyyy-MM-dd'T'HH:mm:00XXX")
        : null;

      payload = {
        ...formData,
        assetImage:
          typeof formData.assetImage === "string"
            ? [formData.assetImage]
            : formData.assetImage,
        startTime: isValid(new Date(formData.startTime))
          ? formattedStartTime
          : null,
        endTime: isValid(new Date(formData.endTime)) ? formattedEndTime : null,
        files: formData.files ?? [],
        isPublished,
      };
      if (typeof formData.assetIcon === "string") delete payload.assetIcon;
    } else {
      const formattedStartTime =
        formData.startTime !== null
          ? format(new Date(formData.startTime), "yyyy-MM-dd'T'HH:mm:ssXXX")
          : null;

      const formattedEndTime =
        formData.endTime !== null
          ? format(new Date(formData.endTime), "yyyy-MM-dd'T'HH:mm:ssXXX")
          : null;

      payload = {
        assetId: uuidv4(),
        ...formData,
        assetImage:
          formData.assetImage === ""
            ? [formData.assetImage]
            : formData.assetImage,
        startTime: isValid(new Date(formData.startTime))
          ? formattedStartTime
          : null,
        endTime: isValid(new Date(formData.endTime)) ? formattedEndTime : null,
        files: formData.files ?? [],
        isPublished,
      };
    }

    return payload;
  };

  const { patch: deleteDataRoomFile } = useNetwork();

  const deleteDataRoomFiles = async () => {
    const loanDeletedFiles = loanFormDeletedFiles?.map((data: Json) => ({
      id: data.id,
      time: data.time,
    }));
    const fileData = [...dataRoomDeleteFileID, ...loanDeletedFiles];
    if (fileData.length > 0) {
      deleteDataRoomFile(
        `${APIS.DeleteDataroomFile}/${auctionFormData.folderId}`,
        {
          files: fileData,
        },
        {
          apiResponse: true,
        }
      )
        .then((response) => {
          if (response?.message === "ok") {
            //
          } else {
            errorNotification("failed to delete file");
          }
        })
        .catch((err: any) => {
          errorNotification(err?.message);
        });
    }
  };

  const uploadGcpfileStatus = async () => {
    const folderId = auctionFormData.folderId;
    if (folderId) {
      let loanFiles = [];
      if (isSbaLoan) {
        loanFiles = loanFormDataRoomFiles
          ?.filter((file: Json) => !!file && !file?.fileUrl)
          ?.map((data: Json) => ({
            id: data.id,
            status: data.status,
            time: data.time,
            uploadType: data.uploadType,
            loanType: auctionFormData.loanType,
          }));
      }
      const payload = {
        files: [...gcpFileUploadingStatus, ...loanFiles],
      };
      try {
        const response = await updateDataRoomFileStatus(
          `${APIS.AuctionDataRoom}/${folderId}`,
          payload
        );
        return response;
      } catch (err: any) {
        errorNotification(err?.message);
        return false;
      }
    }
  };

  const postDataRoomUpdatedFile = async () => {
    const payload = {
      ...updatedDataRoomFileName,
    };
    try {
      const response = await updateDataRoomFileNameData(
        `${APIS.updateAuctionDataRoom}`,
        payload,
        {
          apiResponse: true,
        }
      );
      return response?.message === "ok" ? true : false;
    } catch (err: any) {
      errorNotification(err?.messgae);
      return false;
    }
  };

  const form: any = useRef();
  const submitForm = async ({ isPublished = true }: any) => {
    form?.current.requestSubmit();
  };

  const publishForm = async (e: FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);
    setIsPublished(true);
    PublishAuction(getFormDataToSubmit());
  };

  const saveToDraft = async (isPublished: boolean = false) => {
    const uploadLoan = loanFormDataRoomFiles?.filter(
      (file: Json) => !!file?.id
    );
    if (!!uploadLoan?.length && uploadLoan?.length !== 2) {
      return errorNotification(
        "Please upload both or fix your loan files before proceed"
      );
    }
    setIsPublished(isPublished);
    setIsFormLoading(true);
    let formData = getFormDataToSubmit();
    let IsFormvalidated;
    IsFormvalidated = valiDateAuctionname();
    // if validation is successfull
    if (IsFormvalidated) {
      // update existing auction if its in edit mode
      if (editAuctionData.auctionId && editAuctionData.isEditable) {
        const payload = getPayloadToSubmitForm(formData, isPublished);
        updateAuctions(payload, isPublished);
      } else {
        // create a new auction draft
        const payload = getPayloadToSubmitForm(formData, isPublished);
        createAuctions(payload, isPublished);
      }
    } else {
      setIsFormLoading(false);
    }
  };

  const updateDataRoomFilesAfterSuccess = async () => {
    await uploadGcpfileStatus();
    // updating for data room files name change
    if (Object.keys(updatedDataRoomFileName).length > 0) {
      await postDataRoomUpdatedFile();
    }
    await deleteDataRoomFiles();
  };

  const PublishAuction = async (formData: any, isPublished: boolean = true) => {
    let IsFormvalidated = true;
    IsFormvalidated = valiDatePublishForm();
    if (IsFormvalidated) {
      if (editAuctionData.auctionId && editAuctionData.isEditable) {
        // update an existing auction
        const payload = getPayloadToSubmitForm(formData, isPublished);
        updateAuctions(payload, isPublished);
      } else {
        // create a new auction
        const payload = getPayloadToSubmitForm(formData, isPublished);
        createAuctions(payload, isPublished);
      }
    } else {
      setIsFormLoading(false);
    }
  };

  const getDataRoomFilewithOrder = () => {
    const fileArray: any = [];
    dataRoomFiles
      ?.filter((file: any) => !dataRoomDeleteFileID.includes(file.id))
      .forEach((item: any, index: number) => {
        const FileObject = {
          id: item.id ?? null,
          fileIndex: index + 1,
        };
        fileArray.push(FileObject);
      });

    return fileArray;
  };

  const getFormDataToSubmit = useCallback(() => {
    // destrcuture form as per auction type
    let newFormData: any = {};
    const {
      name,
      tradeType,
      category,
      product,
      description,
      assetImage,
      assetIcon,
      startPrice,
      stepPrice,
      buynowPrice,
      isBidAgentEnabled,
      startTime,
      endTime,
      timeStepHours,
      timeStepMinutes,
      dutchStepQuantity,
      dutchDuration,
      antiSnipTimeStep,
      folderId,
      antiSnipTimeToAdd,
      symbol,
      symbolValue,
      reservePrice,
      sbaType,
      currentLoanBalance,
      currentYield,
      totalWeightedLoanBalance,
      loanType,
    } = auctionFormData;
    if (tradeType === "classic") {
      newFormData = {
        startPrice: parseFloat(startPrice) || 0,
        stepPrice: parseInt(stepPrice) || 1,
        buynowPrice: parseFloat(buynowPrice) || 0,
        antiSnipTimeStep: antiSnipTimeStep || 0,
        antiSnipTimeToAdd: antiSnipTimeToAdd || 0,
        isBidAgentEnabled,
        startTime,
        endTime,
        folderId,
        files: getDataRoomFilewithOrder(),
        symbol,
        symbolValue: symbolValue?.toUpperCase()?.trim(),
        name: name?.trim(),
        tradeType,
        category,
        product,
        description: description.trim(),
        assetImage,
        assetIcon,
      };
    } else {
      newFormData = {
        startPrice: parseFloat(startPrice) || 5,
        stepPrice: parseInt(stepPrice) || 1,
        reservePrice: parseFloat(reservePrice) || 1,
        timeStepHours: timeStepHours || 0,
        timeStepMinutes: timeStepMinutes || 0,
        dutchStepQuantity: dutchStepQuantity || 0,
        dutchDuration: dutchDuration || 0,
        startTime,
        endTime,
        folderId,
        files: getDataRoomFilewithOrder(),
        symbol,
        symbolValue: symbolValue?.toUpperCase()?.trim(),
        name: name?.trim(),
        tradeType,
        category,
        product,
        description,
        assetImage,
        assetIcon,
      };
    }
    if (product === "SBA-7" && category === "Loans") {
      newFormData.sbaType = sbaType;
      newFormData.loanType = loanType;
      newFormData.stepPrice = parseFloat(
        ((currentLoanBalance * stepPrice) / 100).toFixed(2)
      );
      newFormData.startPrice = parseFloat(
        ((currentLoanBalance * startPrice) / 100).toFixed(2)
      );
      newFormData.buynowPrice = parseFloat(
        ((currentLoanBalance * buynowPrice) / 100).toFixed(2)
      );
      newFormData.currentLoanBalance = currentLoanBalance || 0;
      newFormData.currentYield = currentYield || 0;
      newFormData.totalWeightedLoanBalance = totalWeightedLoanBalance || 0;
      if (newFormData.buynowPrice === 0) {
        delete newFormData.buynowPrice;
      }
      if (tradeType === "dutch") {
        newFormData.reservePrice = parseFloat(
          ((currentLoanBalance * reservePrice) / 100).toFixed(2)
        );
      }
    }
    return newFormData;
  }, [auctionFormData, dataRoomDeleteFileID, dataRoomFiles]);

  const formHeading: any = {
    classic: "Classic Auction Params",
    dutch: "Parameters of dutch auction",
  };

  const updateFileFolderId = (folderId: string) => {
    setAuctionFormData((prev: any) => ({ ...prev, folderId }));
  };

  const updatedDataRoomfiles = useCallback((dataRoomFiles: any) => {
    const tempFiles = { ...dataRoomFiles };
    const updatedFileObject: any = {
      files: [],
    };
    const propertyNames = Object.keys(tempFiles);

    propertyNames?.forEach((id: any) => {
      const object: any = {
        id,
        docName: tempFiles[id],
        time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
      };
      updatedFileObject.files.push(object);
    });

    if (updatedFileObject.files.length > 0) {
      setUpdatedDataRoomFileName({ ...updatedFileObject });
    }
  }, []);

  const closeAuction = () => {
    window.history.back();
  };

  const setFileCheckLoading = useCallback(
    (value: boolean) => {
      setIsFileLoading(value);
    },
    [isFileLoading]
  );

  return (
    <div className="auction-create-container">
      <form ref={form} className={`auction-wrapper`} onSubmit={publishForm}>
        <div className={`${isFormLoading ? "disable-editing" : ""}`}>
          <div className="auction-form-container">
            <div className="auction-form-header-container">
              <p className="auction-form-header">Main Information</p>
              <span
                role="button"
                className="auction-close-btn"
                onClick={closeAuction}
              >
                <i className="ri-close-fill ri-xl"></i>
              </span>
            </div>
            <AuctionBasicForm
              handleImageInputChange={handleImageInputChange}
              setAuctionFormData={setAuctionFormData}
              auctionFormData={auctionFormData}
              setAssetTrade={setAssetTradeType}
              onChangeHandler={onChangeHandler}
            />
          </div>
          {auctionFormData.product === "SBA-7" &&
            auctionFormData.category === "Loans" && (
              <div className="auction-form-container">
                <div className="auction-form-header-container">
                  <p className="auction-form-header">Loan Information</p>
                </div>
                <AuctionLoanForm
                  setAuctionFormData={setAuctionFormData}
                  auctionFormData={auctionFormData}
                  setLoanFormDataRoomFiles={setLoanFormDataRoomFiles}
                  loanFormDataRoomFiles={loanFormDataRoomFiles}
                  setLoanFormDeletedFiles={setLoanFormDeletedFiles}
                  setFileCheckLoading={setFileCheckLoading}
                />
              </div>
            )}
          <div className="auction-form-container">
            <div className="auction-form-header-container">
              <p className="auction-form-header">Data Room</p>
            </div>
            <AuctionDataRoom
              updateFileFolderId={(id: string) => updateFileFolderId(id)}
              publishtnDisabled={(value: boolean) => setBtnDisabled(value)}
              auctionFormData={auctionFormData}
              setAuctionFormData={setAuctionFormData}
              updatedDataRoomfiles={(files: any) => updatedDataRoomfiles(files)}
              updateDataRoomFileOrder={(files: any) => setDataRoomFiles(files)}
              setGcpFileUploadingStatus={(status: any) =>
                setGcpFileUploadingStatus((prev: any) => [...prev, ...status])
              }
              dataRoomDeleteFileID={(file: any) =>
                setDataRoomDeleteFileID((prev: any) => [...prev, file])
              }
              deletedFiles={dataRoomDeleteFileID}
              dataRoomFilesRes={dataRoomFilesRes}
            />
          </div>
          <div className="auction-form-container">
            <p className="auction-form-header">{formHeading[assetTradeType]}</p>
            {isSbaLoan && !!auctionFormData?.currentLoanBalance && (
              <>
                <AuctionLoanType
                  auctionFormData={auctionFormData}
                  setAuctionFormData={setAuctionFormData}
                />
                <div className="auction-commision-wrapper">
                  <AmountCommision
                    heading="Current loan balance"
                    Amount={auctionFormData?.currentLoanBalance}
                    format={true}
                    isLoading={isFileLoading}
                  />
                  <AmountCommision
                    heading="Weighted Average Coupon"
                    Amount={` ${(auctionFormData?.currentYield || 0)?.toFixed(
                      6
                    )}%`}
                    format={false}
                    isLoading={isFileLoading}
                  />
                </div>
              </>
            )}
            <div className="auction-form-main-wrapper">{renderForm}</div>
          </div>
        </div>
      </form>

      <div className="auction-btn-wrapper">
        <div className="create-asset-btn">
          {!auctionFormData.isPublished && (
            <Button
              disabled={btnDisbaled || isFormLoading || loading}
              label={
                isFormLoading && !isPublished ? (
                  <Loader className="green-transparent" dimension={15} />
                ) : (
                  "Save to draft"
                )
              }
              type="btn-grey btn-h-auto btn-m"
              buttonType="button"
              handleClick={() => saveToDraft(false)}
            />
          )}
          <Button
            disabled={btnDisbaled || isFormLoading || loading || isFileLoading}
            label={
              isFormLoading && isPublished ? (
                <Loader className="white-transparent" dimension={15} />
              ) : editAuctionData.isEditable && auctionFormData.isPublished ? (
                "Update Auction"
              ) : (
                "Publish"
              )
            }
            type="button-green asset-btn submit btn-m"
            buttonType="button"
            handleClick={() => submitForm({ isPublished: true })}
          />
        </div>
      </div>
    </div>
  );
};
