import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { DatePicker,Loader } from "components";
import { useCurrency, useNetwork, useNotification } from "hooks";
import { getFormattedDate, getJsonToParams } from "utils";
import {
  SelectedDateRangeState,
  transactionDataState,
} from "states";
import { APIS } from "constant";
import { ReportsChart, ReportTable } from "./components";
import { formatNumberWithCommas } from "@utils/format-number";

import styles from "./report.module.sass";
import "./report.scss";

import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { Json } from "../../types";
import { seriesConfigs, seriesConfigsPortfolio } from "./components/chart-details/constant";
import { CookieStorage } from "@segment/analytics-next/dist/types/core/storage";

export const Reports = () => {
  const initialQuery={ limit: 20, offset: 0 }
  const [filters, setFilters] = useState(initialQuery);
  const [enableFilterButton, setEnableFilterButton] = useState(false);

  
  const setTransactionData = useSetRecoilState(transactionDataState);
  const resetTransaction = useResetRecoilState(transactionDataState);
  const selectedDateRange = useRecoilValue(SelectedDateRangeState);
  const { errorNotification } = useNotification();
  const transactionRef = useRef<any>();
  const [reportData, setReportData] = useState<any>([]); //Todo
  const { endDate, startDate } = selectedDateRange[0];

  const { get: formDownload, loading: loading_8949 } = useNetwork();
  const { post: updateReports, loading } = useNetwork();
  const { trackPageEvent } = useFullStoryTrackEvent();
  const [reportCharts, setReportCharts] = useState<any>([]);
  const {formatCurrencyWithBillion} = useCurrency();

  
  const {
    get: getReportCharts,
    loading: chartLoader,
  } = useNetwork();
  
  const {
    get: getTransactionReports,
    data: getTransactionData,
    loading: reportDataLoading,
  } = useNetwork();

  useEffect(() => {
    trackPageEvent({
      pageName: 'Reports',
    });
  }, []);

  useEffect(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);

    if(enableFilterButton){
      setEnableFilterButton(true)
       alert("vcgdhsjdjjbj")
       return;
    }else{
      setEnableFilterButton(false)

      getReportCharts(`${APIS.REPORTS_CHARTS}?to=${endDates}&from=${fromDate} `).then((response: any) => {
        if(response?.message === 'ok'){
          setReportCharts(response);
        }
      }).catch((err: any) => {
        console.log(err);
       })
    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  useEffect(() => {
    // reset the state
    resetTransaction();
    setReportData([])
    setFilters(initialQuery)
    if (transactionRef.current) {
      transactionRef.current.scrollTo({
        top: 0,
      });
    }
  }, [selectedDateRange])


  useEffect(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);
    const query = {
      to: endDates,
      from: fromDate,
      ...filters
    }
    getTransactionReports(
      `${APIS.Transaction_exchange}` + getJsonToParams(query)
    );

  }, [filters]);

  useEffect(() => {
    if (getTransactionData?.data) {
      setTransactionData((prev: Json[]) => [...prev, ...getTransactionData?.data]);
      setReportData((prev: Json[]) => [...prev, ...getTransactionData?.data]);
    }
  }, [getTransactionData?.data, setTransactionData]);

  const handleFetchMore = useCallback(() => {
    setFilters((prev) => ({ ...prev, offset: prev.offset + 1 }));
  }, []);

  const formatDate = useCallback((date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }, []);

  const handleOnClickDateFilter = useMemo(() => {
    const fromDates = new Date(formatDate(startDate)).getTime();
    const endDates = new Date(formatDate(endDate)).getTime();
    const filteredArr = [...reportData]?.filter((item) => {
      let date = new Date(formatDate(item.dateOfBuy)).getTime();
      if (date >= fromDates && date <= endDates) {
        return item;
      }
      return null;
    });
    // setTransactionData(filteredArr);
  }, [endDate, formatDate, reportData, startDate]);

  const handleStatement = useCallback(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);

    updateReports(
      `${APIS.MONTHLYSTATEMENT}
  `,
      { to: endDates, from: fromDate }
    ).then((res) => {
      if (res.signedUrl) {
        window.open(res.signedUrl, "_blank");
      } else {
        errorNotification("Not able to download please try after sometime");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const handleDownload_8949 = useCallback(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);
    formDownload(`${APIS.FormDownload}?to=${endDates}&from=${fromDate}`).then(
      (res) => {
        window.open(res.data?.[0]?.formSignedUrl, "_blank");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const report_Charts = useMemo(() => {
    return (
      <div className={styles.charts}>
        <ReportsChart
          title="Profit & Loss"
          response={reportCharts}
          dataKey="intervalPnL"
          seriestype={seriesConfigs}
          chartType="profit"
          loading={chartLoader}
        />
        <ReportsChart
          title="Portfolio"
          response={reportCharts}
          dataKey="intervalCurrentInvested"
          seriestype={seriesConfigsPortfolio}
          chartType="portfolio"
          loading={chartLoader}
        />
      </div>
    );
  }, [reportCharts, chartLoader]);

  const { netRealizedPnL, netUnrealizedPnL, totalCurrentValue, totalInvested } =
    reportCharts?.data ?? {};

   return (
    <div className={styles.reports_container}>
      <div className={styles.container}>
        <p className={styles.section_heading}>Reports</p>
        <div className={`${styles.report_wrapper_button}`}>
          <div className="report_btn">
            <DatePicker onSubmit={() => handleOnClickDateFilter} buttonDisabled={enableFilterButton} setEnableFilterButton={setEnableFilterButton} />
            </div>
            <div
              className={`${styles.container_button} ${styles.container_secondaryButton} disabled-button`}
              onClick={handleStatement}
            >
              {loading ? (
                <Loader className="loader-white" dimension={18} />
              ) : (
                <>
                  Monthly Statement
                </>
              )}
            </div>
            <div
              className={`${styles.container_button} ${styles.container_secondaryButton} disabled-button`}
              onClick={handleDownload_8949}
            >
              {loading_8949 ? (
                <Loader className="loader-white" dimension={18} />
              ) : (
                <>
                Download (8949)
                </>
              )}
            </div>
        </div>
      </div>
      <div className="report_main_section">
      <div className="report_chart_container">
        <div className="reports_charts_data">
          <small>Cost Basis (USD)</small>
          <p className={totalInvested < 0 ? 'profit-negative' : ''}>{totalInvested ? '$'+formatNumberWithCommas(totalInvested) : '--'}</p>
        </div>
        <div className="reports_charts_data">
          <small>Current Value (USD)</small>
          <p className={totalCurrentValue < 0 ? 'profit-negative' : ''}>{totalCurrentValue ? '$'+formatNumberWithCommas(totalCurrentValue) : '--'}</p>
        </div>
        <div className="reports_charts_data">
          <small>Net Realized P&L</small>
          <p className={!netRealizedPnL ? '' : netRealizedPnL < 0 ? 'profit-negative' : 'profit-positive'}>{netRealizedPnL ? netRealizedPnL < 0 ? '-' : '+' + formatCurrencyWithBillion(netRealizedPnL, 2) : '--'}</p>
        </div>
        <div className="reports_charts_data">
          <small>Unrealized P&L</small>
          <p className={!netUnrealizedPnL ? '' : netUnrealizedPnL < 0 ? 'profit-negative' : 'profit-positive'}>{netUnrealizedPnL ? netUnrealizedPnL < 0 ? '-' : '+' + formatCurrencyWithBillion(netUnrealizedPnL, 2) : '--'}</p>
        </div>
      </div>
      <div className={styles.chart_table_container}>
        {report_Charts}
      </div>
      <div className={styles.table_report}>
          <ReportTable
            refToTable={transactionRef}
            loading={reportDataLoading}
            filters={filters}
            handleFetchMore={handleFetchMore}
          />
      </div>
      </div>
      </div>
  );
};

export default Reports
