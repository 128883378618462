import { Json } from "../../types";
import { SBA7A } from "@views/exchange/constants";

export const MY_PORTFOLIO_TABS = [
  { title: "All", url: "", type: "All" },
  { title: "Private Stock", url: "", type: "privates" },
  { title: "Crypto", url: "", type: "crypto" },
  { title: "Real Estate", url: "", type: "real_estate" },
  { title: "Horses", url: "", type: "horse_trade" },
  // { title: "Music", url: "", type: "music" },
  { title: "SBA7", url: "", type: "sba7" },
  { title: "Manufactured Home Loans", url: "", type: "manufactured_home_loans" },
  { title: "Equipment Finance", url: "", type: "equipment_finance" },
  // { title: "Stocks", url: "", type: "stocks" },
  // { title: "Forex", url: "", type: "forex" },
  // { title: "Commodity", url: "", type: "commodities" },
];

export const ASSETS_COLOR: Json = {
  stocks: "#4343EF",
  // music: "#513EAD",
  privates: "#148FE1",
  horse_trade: "#148FE1",
  crypto: "#E1E114",
  real_estate: "#2727EC",
  sba7: "#F5AF45",
  forex: "#2EC1BF",
  commodities: "#F34F2A",
  cash: "#10BC55",
  manufactured_home_loans: "#8F14E1",
  equipment_finance: "#EC2776",
};

export const PORTFOLIO_ASSETS: Json = {
  All: "All",
  privates: "Private Stock",
  horse_trade: "Horses",
  crypto: "Crypto",
  real_estate: "Real Estate",
  // stocks: "stocks",
  // commodities: "commodities",
  // music: "music",
  sba7: SBA7A,
  equipment_finance: "Equipment Finance",
  manufactured_home_loans: "Manufactured Home Loans",
  // forex: "forex",
};

export const CHART_COLORS: Json = {
  privates: ["#148FE1", "#148FE1"],
  horse_trade: ["#148FE1", "#148FE1"],
  real_estate: ["#2727EC", "#2727EC"],
  crypto: ["#E1E114", "#E1E114"],
  stocks: ["#4343EF", "#4343EF"],
  commodities: ["#F34F2A", "#F34F2A"],
  forex: ["#2EC1BF", "#2EC1BF"],
  // music: ["#513EAD", "#513EAD"],
  cash: ["#10BC55", "#10BC55"],
  sba7: ["#F5AF45", "#F5AF45"],
  manufactured_home_loans: ["#8F14E1", "#8F14E1"],
  equipment_finance: ["#f54ca5", "#f54ca5"]
};
export const ASSET_TYPE = {
  PRIVATES: "privates",
  REALESTATE: "real_estate",
  HORSES: "horse_trade",
  CRYPTO: "crypto",
  MUSIC: "music",
  SBA7: "sba7",
  MHL: "manufactured_home_loans",
  EF: "equipment_finance",
};

export const noDataMessage = {
  NO_PRIVATES_DATA: "No Private Stock data available.",
  NO_HORSE_DATA: "No Horse data available.",
  NO_REALESTATE_DATA: "No Real Estate data available.",
  NO_CRYPTO_DATA: "No Crypto data available.",
  NO_MUSIC_DATA: "No Music data available.",
  NO_SBA7_DATA: "No SBA(7a) data available.",
  NO_MHL_DATA: "No Manufactured Home Loan data available.",
  NO_EF_DATA: "No Equipment Finance data available.",
};
