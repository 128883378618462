import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Button,
  CountdownTimer,
  Loader,
  SkeletonLogo,
  SkeletonTable,
  Input,
  RadialSeparators,
} from "../../../components";

import "./auction-details.scss";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
} from "react-circular-progressbar";
import { AuctionDataRoom, LatestBids } from "../components";
import {
  useAuctionApis,
  useCurrency,
  useNetwork,
  useNotification,
  usePrevious,
  useRemainingSecPercentage,
} from "hooks";
import { APIS } from "../../../constant";
import { useParams } from "react-router-dom";
import { Json } from "../../../types";
import { useRecoilValue } from "recoil";
import {
  AuctionBidPostState,
  userSelectedCurrencyState,
} from "../../../states";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Image } from "../../../@storybook";
import { BID_ACTION_TABS, LOAN_NAME } from "../constant";
import { addMinutes, subMinutes } from "date-fns";
import { BidSheetLoan } from "@views/auction/create-auction/components/BidSheetLoan";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { imageConfig } from "@utils/imageConfig";

export const AuctionDetails = () => {
  const params = useParams();
  const { errorNotification, successNotification } = useNotification();
  const { formatCurrencyWithBillion, bondPointToAmount } = useCurrency();
  const { postAuctionBid } = useAuctionApis();
  const currency = useRecoilValue(userSelectedCurrencyState);
  const { data: placeBidData = {}, loading: placeBidLoading } =
    useRecoilValue(AuctionBidPostState);
  const prevPlaceBidLoading = usePrevious(placeBidLoading);
  const { get: fetchAuction } = useNetwork({ updateState: false });
  const {
    remove: removeUserMaxBid,
    data: removeMaxBidData,
    loading: isRemovingMaxBid,
  } = useNetwork();
  const prevIsRemovingMaxBid = usePrevious(isRemovingMaxBid);
  const [auctionData, setAuctionData] = useState<Json>({});
  const { data: auctionDetails = {}, loading } = auctionData;
  const [stepMultiplier, setStepMultiplier] = useState(1);
  const [maxBidPrice, setMaxBidPrice] = useState("");
  const [bidActionTab, setBidActionTab] = useState("bidNow");
  const { calculateWeightedYieldPer, sbaAndNormalPrice, isSbaLoan } =
    useAuctionHook(auctionDetails);

  const {images: {pattern}} = imageConfig;  
  
  const secPercentage = useRemainingSecPercentage(
    auctionDetails?.startTime,
    auctionDetails?.endTime,
    auctionDetails?.status === "live"
  );
  const prevDutchStepTime = useMemo(() => {
    return subMinutes(
      new Date(auctionDetails?.dutchPriceUpdateTime),
      auctionDetails?.timeStepHours * 60 + auctionDetails?.timeStepMinutes
    );
  }, [auctionDetails]);
  const isDutchLive =
    auctionDetails?.tradeType === "dutch" && auctionDetails?.status === "live";
  const dutchStepTimePer = useRemainingSecPercentage(
    prevDutchStepTime,
    auctionDetails?.dutchPriceUpdateTime,
    isDutchLive
  );

  const yourBidPrice = useMemo(() => {
    return (
      parseFloat(auctionDetails?.currentBidPrice) +
      parseFloat(auctionDetails?.stepPrice) * stepMultiplier
    );
  }, [stepMultiplier, auctionDetails, currency]);

  useEffect(() => {
    handleFetchAuction();
  }, []);

  const handleFetchAuction = useCallback(async () => {
    setAuctionData({ loading: true });
    const res = await fetchAuction(`${APIS.Auction}/${params.id}`);
    if (res?.message === "ok") {
      setAuctionData({ loading: false, data: res?.data });
      setStepMultiplier(!!res?.data?.totalBid ? 1 : 0);
    } else {
      setAuctionData({ loading: false });
      errorNotification(
        res?.message || "Something went wrong! Please try again"
      );
    }
  }, [params]);

  const renderStartDate = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json) => {
      if (completed && auctionDetails?.status === "yet-to-start") {
        setAuctionData((prev) => {
          let temp = JSON.parse(JSON.stringify(prev.data));
          temp = { ...temp, status: "live" };
          return { ...prev, data: { ...temp } };
        });
      }
      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [auctionDetails]
  );

  const renderDate = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json, type?: string) => {
      if (type === "endTimer" && completed) {
        setAuctionData((prev) => {
          let temp = JSON.parse(JSON.stringify(prev.data));
          temp = { ...temp, status: "completed" };
          return { ...prev, data: { ...temp } };
        });
      }

      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [auctionDetails]
  );

  const renderDutchStepTime = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json) => {
      if (
        completed &&
        auctionDetails?.currentBidPrice > auctionDetails?.reservePrice
      ) {
        setAuctionData((prev) => {
          let temp = JSON.parse(JSON.stringify(prev.data));
          const {
            currentBidPrice,
            stepPrice,
            maxAuctionBidPrice,
            userMaxBidPrice,
            reservePrice,
            timeStepMinutes,
            timeStepHours,
            dutchPriceUpdateTime,
          } = temp || {};
          const price = Math.max(
            currentBidPrice - stepPrice,
            maxAuctionBidPrice,
            reservePrice
          );

          if (price === maxAuctionBidPrice) {
            return {
              ...prev,
              data: {
                ...temp,
                currentBidPrice: price,
                userBidPrice: userMaxBidPrice,
                status: "completed",
              },
            };
          }

          const totalMinutes =
            price === reservePrice ? 0 : timeStepHours * 60 + timeStepMinutes;
          temp = {
            ...temp,
            currentBidPrice: price,
            dutchPriceUpdateTime: addMinutes(
              new Date(dutchPriceUpdateTime),
              totalMinutes
            ),
          };
          return { ...prev, data: { ...temp } };
        });
      }

      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [auctionDetails]
  );

  const handlePlaceBid = useCallback(
    (type: string) => {
      const payload: Json = {
        assetId: auctionDetails?.assetId,
        auctionId: auctionDetails?.id,
        isBuyNowPrice: bidActionTab !== "bidNow",
      };
      if (bidActionTab === "buyNow")
        payload.bidPrice = auctionDetails?.buynowPrice;
      else if (type === "maxBid" && maxBidPrice)
        payload.maxBidPrice = isSbaLoan
          ? bondPointToAmount(auctionDetails?.currentLoanBalance, maxBidPrice)
          : parseFloat(maxBidPrice);
      else {
        setMaxBidPrice("");
        payload.bidPrice = yourBidPrice;
      }

      postAuctionBid(payload);
    },
    [auctionDetails, yourBidPrice, maxBidPrice, bidActionTab, isSbaLoan]
  );

  useEffect(() => {
    if (
      placeBidData?.message !== "ok" &&
      !placeBidLoading &&
      prevPlaceBidLoading
    ) {
      errorNotification(placeBidData?.message);
    }
    if (
      placeBidData?.message === "ok" &&
      !placeBidLoading &&
      prevPlaceBidLoading
    ) {
      const {
        currentBidPrice,
        endTime,
        finalPrice,
        bidStatus,
        status,
        userBidPrice,
        userMaxBidPrice,
        maxAuctionBidPrice,
      } = placeBidData?.data || {};
      setStepMultiplier(1);
      setMaxBidPrice("");
      setAuctionData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev.data));
        temp = {
          ...temp,
          currentBidPrice,
          endTime,
          finalPrice,
          bidStatus,
          status,
          userBidPrice,
          userMaxBidPrice,
          maxAuctionBidPrice,
        };
        return { ...prev, data: { ...temp } };
      });
      successNotification("Bid placed successfully");
    }
  }, [placeBidData]);

  const handleRemoveUserMaxBidPrice = useCallback(() => {
    removeUserMaxBid(
      `${APIS.AuctionMaxBid}/${auctionDetails?.id}`,
      {},
      { apiResponse: true }
    );
  }, [auctionDetails]);

  useEffect(() => {
    if (
      removeMaxBidData?.message !== "ok" &&
      !isRemovingMaxBid &&
      prevIsRemovingMaxBid
    ) {
      errorNotification(removeMaxBidData?.message);
    }

    if (
      removeMaxBidData?.message === "ok" &&
      !isRemovingMaxBid &&
      prevIsRemovingMaxBid
    ) {
      setAuctionData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev.data));
        temp = { ...temp, userMaxBidPrice: 0 };
        return { ...prev, data: { ...temp } };
      });
      successNotification("User max bid successfully removed");
    }
  }, [removeMaxBidData]);

  const handleChangeUserInput = useCallback(
    (e: any) => {
      const validPrice = /^(?!0{2,})(?!\.$)[0-9]{0,10}(?:[.][0-9]{0,4})?$/.test(
        e.target.value
      );

      if (e.target.value >= 0 && validPrice) setMaxBidPrice(e.target.value);
    },
    [stepMultiplier]
  );

  const renderMaxBidInfo = useMemo(() => {
    const content =
      auctionDetails?.tradeType === "classic"
        ? "Once you submit your maximum bid, our automated system starts placing bids on your behalf. It will start with the current bid price and incrementally increase the bid until it reaches your maximum bid amount."
        : "Once you submit your maximum budget, if your maximum budget is equal to or lower than the maximum budget set for the auction, you will be considered the winning bidder.";
    return (
      <div className="info-icon">
        <i className="ri-information-fill" data-tooltip-id={params.id} />
        <ReactTooltip id={params.id} place="top" content={content} />
      </div>
    );
  }, [auctionDetails]);

  const showMaxBidInput = useMemo(() => {
    if (auctionDetails?.tradeType === "classic") {
      return (
        !auctionDetails?.userMaxBidPrice ||
        auctionDetails?.currentBidPrice > auctionDetails?.userMaxBidPrice
      );
    } else {
      return !auctionDetails?.userMaxBidPrice;
    }
  }, [auctionDetails]);

  const renderWeightedYieldPer = useCallback(
    (value: string | number) => {
      let amount: any = value || 0;
      const avgYieldPer = calculateWeightedYieldPer(auctionDetails, amount, 6);
      return `Equivalent weighted avg. yield : ${avgYieldPer}%`;
    },
    [auctionDetails]
  );

  const renderInputDesc = useMemo(() => {
    if (isSbaLoan) {
      const amount =
        bondPointToAmount(auctionDetails?.currentLoanBalance, maxBidPrice) || 0;
      const avgYieldPer = calculateWeightedYieldPer(auctionDetails, amount, 6);
      return (
        <>
          Equivalent value : {formatCurrencyWithBillion(amount, 2)}
          <br />
          Equivalent weighted avg. yield : {avgYieldPer}%
        </>
      );
    }
    return "";
  }, [auctionDetails, currency, maxBidPrice, isSbaLoan]);

  return (
    <>
      <div className="auction-details-container auction--detail">
        <div className="details-and-latest-bids">
          <div className="details-container">
            <h2 className="auction-details-heading">Overview</h2>
            {!loading && (
              <div className="auction-details-image">
                {!!auctionDetails?.assetImage?.[0] && (
                  <img
                    src={auctionDetails?.assetImage?.[0]}
                    alt="auction detail image"
                  />
                )}
                {!auctionDetails?.assetImage?.[0] && (
                  <i className="ri-image-add-line" />
                )}
              </div>
            )}
            {loading && <SkeletonLogo className="auction-details-image" />}
            {loading && (
              <SkeletonTable
                listsToRender={2}
                numberColumn={1}
                className="border-b-0"
              />
            )}
            {!loading && (
              <div className="auction-details">
                <div className="title-heading-symbol">
                  <h2 className="auction-name">{auctionDetails?.name}</h2>
                  <span className="title-symbol">
                    {auctionDetails?.symbol}.
                    <span>{auctionDetails?.symbolValue}</span>
                  </span>
                </div>
                <p className="auction-desc">{auctionDetails?.description}</p>
              </div>
            )}
          </div>
          <div className="auction-latest-bids">
            <LatestBids
              auctionId={params.id!}
              auctionDetails={auctionDetails}
            />
          </div>
        </div>

        <div className="auction-stats-container stats-background">
        
          <div className="stats-row stats-row-1">
            <div style={{height: "100%", display: "grid"}}>
             <div className="stats stats-1">
              <h2 className="auction-details-heading">Auction Stats</h2>
              <div className="auction-timer-stats">
                {loading && <SkeletonLogo className="stats-percent" />}
                {!loading && (
                  <div className="stats-percent">
                    <CircularProgressbarWithChildren
                      maxValue={100}
                      strokeWidth={6}
                      counterClockwise
                      className={`auction-progress-bar ${
                        (secPercentage || 0) < 10 ? "time-red" : ""
                      } ${auctionDetails?.status === "completed" && "time-over"}`}
                      value={
                        auctionDetails?.status === "completed"
                          ? 0
                          : auctionDetails?.status === "live"
                          ? secPercentage || 0
                          : 100
                      }
                    >
                      <RadialSeparators count={100} />

                      {auctionDetails?.tradeType === "dutch" &&
                        auctionDetails?.status !== "yet-to-start" && (
                          <CircularProgressbar
                            className="inner-time-circle"
                            counterClockwise
                            value={
                              auctionDetails?.status === "completed"
                                ? 0
                                : dutchStepTimePer || 0
                            }
                            maxValue={100}
                            strokeWidth={5}
                          />
                        )}
                      <div className="stats-item text-center">
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.currentBidPrice,
                            brake: true,
                            labelClass: "stat-label",
                          })}
                        </p>
                        <p className="stat-label">
                          {auctionDetails?.status === "live" && (
                            <CountdownTimer
                              dateTime={auctionDetails?.endTime}
                              renderer={renderDate}
                            />
                          )}
                          {auctionDetails?.status !== "live" && "00:00:00"}
                        </p>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                )}
                {loading && (
                  <SkeletonTable
                    listsToRender={3}
                    numberColumn={3}
                    className="border-b-0"
                  />
                )}
                {!loading && (
                  <div className="stats-details">
                    <div className="stats-item">
                      <p className="stat-label">Status</p>
                      <p className="stat-value">
                        {auctionDetails?.status?.split("-").join(" ")}
                      </p>
                    </div>
                    <div className="stats-item">
                      <p className="stat-label">Auction Type</p>
                      <p className="stat-value">{auctionDetails?.tradeType}</p>
                    </div>
                    <div className="stats-item">
                      <p className="stat-label">
                        {auctionDetails?.status === "yet-to-start"
                          ? "Time left to start"
                          : "Time left for auction"}
                      </p>
                      <p className="stat-value">
                        {auctionDetails?.status === "yet-to-start" && (
                          <CountdownTimer
                            dateTime={auctionDetails?.startTime}
                            renderer={renderStartDate}
                          />
                        )}

                        {auctionDetails?.status === "live" && (
                          <CountdownTimer
                            dateTime={auctionDetails?.endTime}
                            renderer={(value) => renderDate(value, "endTimer")}
                          />
                        )}

                        {auctionDetails?.status === "completed" && "00:00:00"}
                      </p>
                    </div>
                    <div className="stats-item">
                      <p className="stat-label">Starting bid</p>
                      <p className="stat-value">
                        {sbaAndNormalPrice(auctionDetails, {
                          price: auctionDetails?.startPrice,
                        })}
                      </p>
                      {isSbaLoan && (
                        <p className="stat-label">
                          {formatCurrencyWithBillion(
                            auctionDetails?.startPrice,
                            2
                          )}
                        </p>
                      )}
                    </div>
                    {auctionDetails?.tradeType === "classic" && (
                      <div className="stats-item">
                        <p className="stat-label">Buy Now Price</p>
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.buynowPrice,
                          })}
                        </p>
                        {isSbaLoan && (
                          <p className="stat-label">
                            {formatCurrencyWithBillion(
                              auctionDetails?.buynowPrice,
                              2
                            )}
                          </p>
                        )}
                      </div>
                    )}
                    {auctionDetails?.tradeType === "dutch" && (
                      <div className="stats-item">
                        <p className="stat-label">Reserve price</p>
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.reservePrice,
                          })}
                        </p>
                        {isSbaLoan && (
                          <p className="stat-label">
                            {formatCurrencyWithBillion(
                              auctionDetails?.reservePrice,
                              2
                            )}
                          </p>
                        )}
                      </div>
                    )}
                    {isSbaLoan && (
                      <div className="stats-item">
                        <p className="stat-label">Weighted Average Coupon</p>
                        <p className="stat-value">
                          {(auctionDetails?.metadata?.currentYield || 0)?.toFixed(
                            6
                          )}
                          %
                        </p>
                      </div>
                    )}
                    {isSbaLoan && (
                      <div className="stats-item">
                        <p className="stat-label">Listing type</p>
                        <p className="stat-value">
                          {auctionDetails?.sbaType || "--"}
                        </p>
                      </div>
                    )}
                    {isSbaLoan && (
                      <div className="stats-item">
                        <p className="stat-label">Loan Type</p>
                        <p className="stat-value">
                          {LOAN_NAME?.[auctionDetails?.metadata?.loanType] ||
                            "--"}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
             </div>
              <AuctionDataRoom auctionDetails={auctionData} />
            </div>
            <div className="bid-place-container stats stats-1">
              {auctionDetails?.tradeType === "classic" && (
                <div className="line-tabs mb-10">
                  {BID_ACTION_TABS.map((tab) => (
                    <div
                      key={tab.type}
                      className={`line-tabs_item ${
                        bidActionTab === tab.type ? "active" : ""
                      }`}
                      onClick={() => setBidActionTab(tab.type)}
                    >
                      {tab.title}
                    </div>
                  ))}
                </div>
              )}
              {bidActionTab === "bidNow" && (
                <>
                  {loading && (
                    <SkeletonTable
                      listsToRender={5}
                      numberColumn={2}
                      className="border-b-0"
                    />
                  )}
                  {!loading && (
                    <div
                      className={`stats-details ${
                        auctionDetails?.tradeType === "dutch" ? "rm-border" : ""
                      }`}
                    >
                      <div className="stats-item">
                        <p className="stat-label">Current bid price</p>
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.currentBidPrice,
                          })}
                        </p>
                        {isSbaLoan && (
                          <p className="stat-label">
                            {formatCurrencyWithBillion(
                              auctionDetails?.currentBidPrice,
                              2
                            )}
                          </p>
                        )}
                      </div>
                      <div className="stats-item">
                        <p className="stat-label">Price Step</p>
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.stepPrice,
                          })}
                        </p>
                        {isSbaLoan && (
                          <p className="stat-label">
                            {formatCurrencyWithBillion(
                              auctionDetails?.stepPrice,
                              2
                            )}
                          </p>
                        )}
                      </div>
                      {auctionDetails?.tradeType === "dutch" && (
                        <div className="stats-item">
                          <p className="stat-label">Time left to next step</p>
                          <p className="stat-value">
                            {auctionDetails?.status === "live" && (
                              <CountdownTimer
                                dateTime={auctionDetails?.dutchPriceUpdateTime}
                                renderer={renderDutchStepTime}
                              />
                            )}
                            {auctionDetails?.status !== "live" && "00:00:00"}
                          </p>
                        </div>
                      )}
                      {auctionDetails?.tradeType === "classic" && (
                        <div className="stats-item">
                          <p className="stat-label">Your last bid</p>
                          <p className="stat-value">
                            {sbaAndNormalPrice(auctionDetails, {
                              price: auctionDetails?.userBidPrice,
                            })}
                            {!!auctionDetails?.userBidPrice && (
                              <span
                                className={`last-bid-status ${
                                  auctionDetails?.bidStatus === "highest-bid"
                                    ? "success"
                                    : ""
                                }`}
                              >
                                {auctionDetails?.bidStatus === "highest-bid"
                                  ? "Highest"
                                  : "Outbid"}
                              </span>
                            )}
                          </p>
                          {isSbaLoan && (
                            <p className="stat-label">
                              {formatCurrencyWithBillion(
                                auctionDetails?.userBidPrice,
                                2
                              )}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {!loading && (
                    <div className="user-input-bid-container row-colon">
                      {showMaxBidInput && (
                        <Input
                          suffixLabelIcon={renderMaxBidInfo}
                          placeholder="Enter your max bid"
                          label={
                            auctionDetails?.tradeType === "classic"
                              ? "Choose your maximum bid"
                              : "My max budget"
                          }
                          value={maxBidPrice || ""}
                          inputType="number"
                          handleChange={handleChangeUserInput}
                          suffixText={
                            isSbaLoan
                              ? parseFloat(maxBidPrice) > 1
                                ? "bond points"
                                : "bond point"
                              : currency.code
                          }
                          isShowButton
                          buttonDisabled={
                            !maxBidPrice ||
                            placeBidLoading ||
                            auctionDetails?.status !== "live"
                          }
                          buttonLabel={
                            !!maxBidPrice && placeBidLoading ? (
                              <>
                                <Loader
                                  className="green-transparent"
                                  dimension={15}
                                />{" "}
                                Confirming
                              </>
                            ) : (
                              `Confirm`
                            )
                          }
                          handleClickBtn={() => handlePlaceBid("maxBid")}
                          inputPrefixDesc={
                            auctionDetails?.tradeType === "classic" ? (
                              <>
                                Enter bid greater than{" "}
                                {sbaAndNormalPrice(auctionDetails, {
                                  price:
                                    auctionDetails?.currentBidPrice +
                                    auctionDetails?.stepPrice,
                                })}
                              </>
                            ) : (
                              ""
                            )
                          }
                          inputDesc={renderInputDesc}
                        />
                      )}

                      {!showMaxBidInput && (
                        <div className="max-bid-details">
                          <p className="stat-label">
                            Your current max.{" "}
                            {auctionDetails?.tradeType === "classic"
                              ? "bid"
                              : "budget"}
                          </p>
                          <div>
                            <p className="stat-value">
                              <span>
                                {sbaAndNormalPrice(auctionDetails, {
                                  price: auctionDetails?.userMaxBidPrice,
                                })}
                              </span>
                              <Button
                                label={"Remove"}
                                type="button-red-light btn-h-auto"
                                handleClick={handleRemoveUserMaxBidPrice}
                                disabled={
                                  isRemovingMaxBid ||
                                  auctionDetails?.status !== "live"
                                }
                              />
                            </p>
                            {isSbaLoan && (
                              <small>
                                {formatCurrencyWithBillion(
                                  auctionDetails?.userMaxBidPrice,
                                  2
                                )}
                              </small>
                            )}
                          </div>
                        </div>
                      )}

                      <p className="separate-line-with-text">
                        <span>OR</span>
                      </p>
                    </div>
                  )}

                  {!loading && auctionDetails?.tradeType === "classic" && (
                    <div className={`stats-details row-colon`}>
                      <p className="stat-label">Current bid price</p>
                      <div>
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.currentBidPrice,
                          })}
                        </p>
                        {isSbaLoan && (
                          <small>
                            {formatCurrencyWithBillion(
                              auctionDetails?.currentBidPrice,
                              2
                            )}
                          </small>
                        )}
                      </div>
                      <p className="stat-label">Price Step</p>
                      <div>
                        <p className="stat-value">
                          {sbaAndNormalPrice(auctionDetails, {
                            price: auctionDetails?.stepPrice,
                          })}
                        </p>
                        {isSbaLoan && (
                          <small>
                            {formatCurrencyWithBillion(
                              auctionDetails?.stepPrice,
                              2
                            )}
                          </small>
                        )}
                      </div>
                      <p className="stat-label">Price step multiplier</p>
                      <div className="bid-multiplier-container">
                        <span
                          className={`bid-plus-minus`}
                          onClick={() =>
                            setStepMultiplier((prev) =>
                              prev - 1 <= 0
                                ? !!auctionDetails?.totalBid
                                  ? 1
                                  : 0
                                : prev - 1
                            )
                          }
                        >
                          -
                        </span>
                        <span className="bid-plus-minus bid-value">
                          {stepMultiplier}
                        </span>
                        <span
                          className={`bid-plus-minus`}
                          onClick={() => setStepMultiplier((prev) => prev + 1)}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  )}

                  {!loading && (
                    <div className="stats-details your-bid-price">
                      {auctionDetails?.tradeType === "classic" && (
                        <div className="stats-item">
                          <p className="stat-value">
                            {sbaAndNormalPrice(auctionDetails, {
                              price: yourBidPrice,
                            })}
                          </p>
                          {isSbaLoan && (
                            <p className="fw-600">
                              {formatCurrencyWithBillion(yourBidPrice, 2)}
                            </p>
                          )}
                          {isSbaLoan && (
                            <p>{renderWeightedYieldPer(yourBidPrice)}</p>
                          )}
                          <p className="stat-label">Your bidding price</p>
                          {!showMaxBidInput && (
                            <div className="max-bid-text">
                              Bidding price must be greater than{" "}
                              {sbaAndNormalPrice(auctionDetails, {
                                price: auctionDetails?.userMaxBidPrice,
                              })}{" "}
                              to place a bid
                            </div>
                          )}
                        </div>
                      )}

                      {auctionDetails?.tradeType === "dutch" && (
                        <div className="stats-item">
                          <p className="stat-value">
                            {sbaAndNormalPrice(auctionDetails, {
                              price: auctionDetails?.currentBidPrice,
                            })}
                          </p>
                          {isSbaLoan && (
                            <p className="fw-600">
                              {formatCurrencyWithBillion(
                                auctionDetails?.currentBidPrice,
                                2
                              )}
                            </p>
                          )}
                          {isSbaLoan && (
                            <p>
                              {renderWeightedYieldPer(
                                auctionDetails?.currentBidPrice
                              )}
                            </p>
                          )}
                          <p className="stat-label">Current bid price</p>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              {bidActionTab === "buyNow" && (
                <div className="buy-now-section">
                  <Image fileName={pattern} />
                  <div className="buy-now-details">
                    <small>Buy Now Price</small>
                    <span>
                      {sbaAndNormalPrice(auctionDetails, {
                        price: auctionDetails?.buynowPrice,
                      })}
                    </span>
                    {isSbaLoan && (
                      <small>
                        {formatCurrencyWithBillion(
                          auctionDetails?.buynowPrice,
                          2
                        )}
                      </small>
                    )}
                    {isSbaLoan && (
                      <small>
                        {renderWeightedYieldPer(auctionDetails?.buynowPrice)}
                      </small>
                    )}
                  </div>
                </div>
              )}

              {!loading && (
                <div className="text-center">
                  {bidActionTab === "buyNow" && (
                    <Button
                      label={
                        placeBidLoading ? (
                          <>
                            <Loader
                              className="white-transparent"
                              dimension={15}
                            />{" "}
                            Buying Now
                          </>
                        ) : (
                          "Buy Now"
                        )
                      }
                      type="button-green buy-btn"
                      handleClick={() => handlePlaceBid("bid")}
                      disabled={
                        auctionDetails?.status !== "live" ||
                        placeBidLoading ||
                        !auctionDetails?.buynowPrice
                      }
                    />
                  )}

                  {bidActionTab !== "buyNow" && (
                    <Button
                      label={
                        !maxBidPrice && placeBidLoading ? (
                          <>
                            <Loader
                              className="white-transparent"
                              dimension={15}
                            />{" "}
                            Placing bid
                          </>
                        ) : (
                          "Place bid"
                        )
                      }
                      type="button-green buy-btn"
                      handleClick={() => handlePlaceBid("bid")}
                      disabled={
                        auctionDetails?.status !== "live" ||
                        placeBidLoading ||
                        auctionDetails?.userMaxBidPrice >= yourBidPrice
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!!auctionDetails?.bidSheetJSON && !!auctionDetails?.sba1502JSON && (
          <BidSheetLoan auctionDetails={auctionDetails} />
        )}
      </div>
    </>
  );
};

export default AuctionDetails;
