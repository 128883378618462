import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSetRecoilState } from "recoil";
import UseChatroomApi from "../store/chatroomApi";
import { AssetChatDataState } from "./state";
import "./ChatList.scss";
import { debounce } from "utils";
import InfiniteScroll from 'react-infinite-scroll-component';
import { SkeletonWallet } from '@components/skeleton-loader';

interface ChatItem {
  _id: string;
  assetId: string;
  name: string;
  logo: string;
  isBlock: boolean;
  readCount: number;
}

interface ChatListProps {
  assetsChat: any;
  setAssetChatList: any;
  setIsAssetChatOpen: any;
  setChatsOnAsset: any;
  setSearchTerm: any;
  searchTerm: string;
}

export const ChatList: React.FC<ChatListProps> = ({
  assetsChat,
  setAssetChatList,
  setIsAssetChatOpen,
  setChatsOnAsset,
  searchTerm, setSearchTerm
}) => {
  const { getUserWiseAssetsList, assetChatLoading } = UseChatroomApi();
  const setAssetChatData = useSetRecoilState(AssetChatDataState);
  const assetChatsLength = assetsChat?.data?.length;
  let initialApiCall = useRef<boolean>(false);

  const pageQuery: any = useMemo(() => ({
    limit: 5,
    offset: assetChatsLength === 0 ? 0 : assetChatsLength - 5
  }), [assetChatsLength]);

  const [filters, setFilters] = useState(pageQuery);

  useEffect(() => {
    if (initialApiCall.current) {
      handleCallUserAPi();
    } else initialApiCall.current = true;
  }, [filters]);

  const handleClick = (asset: ChatItem) => {
    setIsAssetChatOpen(false);
    setAssetChatList(asset);
    setChatsOnAsset({ loading: false, data: [], offset: 0, limit: 5 })
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(debounce((value: string) => {
    setFilters((prev: any) => ({ ...prev, search: value || "", offset: 0 }));
  }, 500), []);

  const handleCallUserAPi = useCallback(async () => {
    const options = { updateNewData: !!filters?.offset };
    getUserWiseAssetsList(filters, options, setAssetChatData);
  }, [filters, getUserWiseAssetsList]);

  const searchHasResults = searchTerm && assetsChat?.data?.length;

  const noAssets = !assetsChat?.data?.length && !filters.search && !assetsChat?.loading;

  return (
    <>
      {!noAssets && (
        <div className="search-input-wrapper">
          <input
            type="text"
            className="search-input"
            placeholder="Search asset name"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debounceSearch(e.target.value);
            }}
          />
          {!searchTerm ? <span className="search-icon">
            <i className="ri-search-line ri-xl"></i>
          </span>
          :
          <span className="empty-icon">
            <i className="ri-close-line ri-xl" onClick={()=>{
              setSearchTerm("");
              debounceSearch("");
              }}>
            </i>
          </span>}
        </div>
      )}

      <div className="chat-list" id="chat-list-scrollableDiv">
        <InfiniteScroll
          dataLength={assetChatsLength || 0}
          next={() => setFilters((prev: any) => ({ ...prev, offset: assetChatsLength }))}
          hasMore={assetChatsLength >= filters?.offset + filters?.limit}
          loader={""}
          scrollableTarget="chat-list-scrollableDiv"
        >
          {noAssets &&  (
            <div className="empty-chat">
              <div className="empty-chat__icon">
                <i className="ri-question-answer-fill ri-3x"></i>
              </div>
              <h1 className="empty-chat__heading">No chats yet</h1>
              <p className="empty-chat__subheading">
                You haven't started a conversation yet.
              </p>
            </div>
  
          )}

          {searchTerm && !searchHasResults && (
            <div className="no-results">No asset found</div>
          )}

          {assetsChat?.data?.map((item: any, index: number) => (
            <div
              key={item?.assetId}
              className="chat-item"
              onClick={() => handleClick(item)}
            >
              {item.logo ? (
                <img src={item.logo} alt={item.name} className="item-image" />
              ) : (
                <span className="chatAssetName">
                  {item.name?.charAt(0).toUpperCase() +
                    item.name?.charAt(1, 2).toUpperCase() ?? ""}
                </span>
              )}
              <div className="item-name">
                {item.name}
              </div>
              {item.unreadCount > 0 && (
                <div className="item-count">{item.unreadCount}</div>
              )}
            </div>
          ))}
        </InfiniteScroll>

        {assetsChat?.loading && <SkeletonWallet listsToRender={3} />}
      </div>
    </>
  );
};
