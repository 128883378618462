import { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { initialQueryCryptoState, CryptoState, WatchlistState } from "states";
import { SkeletonWatchList } from "components";
import { APIS } from "constant";
import { CryptoItem } from "./crypto-item";

import styles from "../Currency/Currency.module.sass";

import { useNetwork } from "hooks";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJsonToParams, getKeyValueObjectFromArray } from "@utils/common";
import { imageConfig } from "@utils/imageConfig";
// @ts-ignore
import { Json } from "@types/common";

export const Crypto = () => {
  const [filters, setFilters] = useRecoilState(initialQueryCryptoState);
  const [cryptoState, setCryptoSate] = useRecoilState(CryptoState);
  const watchlist = useRecoilValue(WatchlistState);
  const darkMode = useDarkMode(false);
  const { get: getCryptoData, loading: loadingISCrypto } = useNetwork();
  const { get: getCrypto } = useNetwork();

  const refAssetInterval = useRef<any>(null);

  const mappedData = useMemo(() => {
    return {
      fetchMoreData: getCryptoData,
      loading: loadingISCrypto,
      setAssetData: setCryptoSate,
      assets: cryptoState,
      intervalFetch: getCrypto,
    }
  }, [loadingISCrypto, cryptoState])


  // This code is for exchange explorer api polling
  const NewObjectWithNewIdAdded = useCallback(
    (assets: any, currentArray: any) => {
      const newAssets = currentArray?.filter((obj: any) => !assets?.[obj?.id]);
      if (newAssets?.length) {
        return getKeyValueObjectFromArray("id", newAssets);
      } else {
        return "";
      }
    },
    []
  );

  useEffect(() => {
    refAssetInterval.current = setInterval(() => {
      mappedData?.intervalFetch(`${APIS.Explorers}?include=crypto`).then((res: any) => {
        if (res?.message === "ok") {
          const newAsset = NewObjectWithNewIdAdded(mappedData.assets, res?.data?.crypto);
          if (newAsset) {
            mappedData?.setAssetData((prev: any) => {
              return {...newAsset, ...prev};
            });
          }
        }
      });
    }, 5000);
    return () => clearInterval(refAssetInterval.current);
  }, [mappedData.assets]);

  const handleFetchMore = useCallback(() => {
    const queryParams = { ...filters, offset: filters.offset + 1 };
    mappedData.fetchMoreData(`${APIS.Explorers}` + getJsonToParams(queryParams)).then((res: any) => {
      if (res) {
        mappedData?.setAssetData((prev: any) => {
          const newAssets = getKeyValueObjectFromArray("id", res?.data?.crypto)
          return {...prev, ...newAssets};
        });
      }
    });
    setFilters((prev) => ({ ...queryParams }));
  }, [filters, mappedData, setFilters]);

  const {svg: {no_data_light, no_data_dark}} = imageConfig;

  const assets = useMemo(() => {
    const watchlistIds = Object.keys(watchlist || {});
    const reorderedObj: Json = {};
    watchlistIds.forEach(id => {
      if (mappedData?.assets?.[id]) {
        reorderedObj[id] = mappedData?.assets?.[id];
      }
    });
    Object.keys(mappedData?.assets || {}).forEach(id => {
      if (!reorderedObj[id]) {
        reorderedObj[id] = mappedData?.assets?.[id];
      }
    });
    return Object?.values?.(reorderedObj || {});
  }, [mappedData?.assets]);

  return (
    <>
      {assets?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image fileName={darkMode.value ? no_data_dark : no_data_light} />
            <div>No Data Found</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={assets?.length || 0}
          next={handleFetchMore}
          hasMore={
            assets?.length >= filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="assets-scrollableDiv"
          style={{ minHeight: "80px" }}
        >
          {assets?.map((data, index) => {
            return (
              <CryptoItem
                amount={0}
                key={data?.id}
                index={index}
                {...(data ?? {})}
                type="crypto"
              />
            );
          })}
        </InfiniteScroll>
      )}
      {mappedData?.loading &&<SkeletonWatchList listsToRender={4} />}
    </>
  );
};
